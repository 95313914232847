import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardHeader, Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { categoryies } from "../../data/categories";
import { getHost } from "../../redux/host/host.slice";
import { getRegions } from "../../redux/regions/region.slice";
import { getCountries } from "../../redux/countries/country.slice";
import { getAirlines } from "../../redux/airlines/airline.slice";
import { getAgencies } from "../../redux/agencies/agency.slice";

const animatedComponents = makeAnimated();

const EditTour = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedHosts, setSelectedHosts] = useState(null);
  const [price, setPrice] = useState(0);
  const [tour, setTour] = useState(0);
  const [space, setSpace] = useState("");
  const [phone, setPhone] = useState("");
  const [region, setRegion] = useState(null);
  const [status, setStatus] = useState("");
  const [ratings, setRatings] = useState(0);
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [official_email, setOfficialEmail] = useState("");
  const [country, setCountry] = useState(null);
  const [url, setUrl] = useState("");
  const [direct, setDirect] = useState("");
  const [website, setWebsite] = useState("");
  const [airbnb, setAirbnb] = useState("");
  const [awa, setAwa] = useState("");
  const [tripeadviser, setTripeadviser] = useState("");
  const [bookingdotcom, setBookingdotcom] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [map_url, setMapUrl] = useState("");
  const [thumb_nail, setThumbnail] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedAirlines, setSelectedAirlines] = useState([]);
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [addedAirlines, setAddedAirlines] = useState([]);
  const [addedAgencies, setAddedAgencies] = useState([]);

  const categoriesOptions = categoryies.map((u) => {
    return { value: u.category, label: u.category };
  });

  const { user } = useSelector((state) => state.auth);
  const { hosts } = useSelector((state) => state.hosts);
  const { regions } = useSelector((state) => state.regions);
  const { countries } = useSelector((state) => state.countries);
  const { airlines } = useSelector((state) => state.airlines);
  const { agencies } = useSelector((state) => state.agencies);

  const [file, setFile] = useState(null);

  const handleChange = (file) => {
    // if (window !== undefined) {
    let formData = new FormData();
    formData.append("thumb_nail", file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(process.env.REACT_APP_API_URL + "upload", formData, config)
      .then(({ data }) => {
        setThumbnail(data.data[0].location);
      })
      .catch((error) => console.log(error));
    // }
  };

  useEffect(() => {
    const fetchData = async () => {
      const APP_URL = process.env.REACT_APP_API_URL + "tours/";
      const { data } = await axios.get(`${APP_URL}${id}`);
      setTour(data);
      setPrice(data?.price);
      setSpace(data?.space);
      setCountry({ label: data?.country?.name, value: data?.country?._id });
      setUrl(data?.url);
      setStatus(data?.status);
      setPhone(data?.phone);
      setRatings(data?.rating);
      setDescription(data?.description);
      setAddress(data?.address);
      setOfficialEmail(data?.official_email);
      setWebsite(data?.website);
      setRegion({ label: data?.region?.name, value: data?.region?._id });
      setSelectedType({ label: data?.tourType, value: data?.tourType });
      setThumbnail(data?.thumb_nail);
      setMapUrl(data?.map);
      setAirbnb(data?.booking?.airbnb);
      setAwa(data?.booking?.awa);
      setTripeadviser(data?.booking?.tripeadviser);
      setBookingdotcom(data?.booking?.bookingdotcom);
      setDirect(data?.booking?.direct);
      setLat(data?.coordinates?.lat);
      setLng(data?.coordinates?.lng);
      setSelectedCategories(
        data?.category?.map((cat) => ({
          value: cat,
          label: cat,
        }))
      );
      setSelectedAgencies(
        data?.agencies?.map((agency) => ({
          value: agency.agency?._id,
          label: agency.agency?.name,
          url: agency.url,
        }))
      );
      setSelectedAirlines(
        data?.airlines?.map((airline) => ({
          value: airline.airline?._id,
          label: airline.airline?.name,
          url: airline.url,
        }))
      );
      setAddedAgencies(
        data?.agencies?.map((agency) => ({
          agency: agency.agency?._id,
          url: agency.url,
        }))
      );
      setAddedAirlines(
        data?.airlines?.map((airline) => ({
          agency: airline.airline?._id,
          url: airline.url,
        }))
      );
      setSelectedHosts({
        label: data?.host?.businessName,
        value: data?.host?._id,
      });
    };

    // dispatch(fetchTourById(id));
    fetchData();
    dispatch(getHost({}));
    dispatch(getRegions({}));
    dispatch(getCountries({}));
    dispatch(getAirlines({}));
    dispatch(getAgencies({}));
  }, [id]);

  const onChangeAirline = (e, airlineName) => {
    const { value, name } = e.target;

    setAddedAirlines((prevAirlines) => {
      const updatedAirlines = [...prevAirlines];
      const index = updatedAirlines.findIndex(
        (airline) => airline.airline === airlineName
      );

      if (index !== -1) {
        updatedAirlines[index] = { airline: airlineName, url: value };
      } else {
        updatedAirlines.push({ airline: airlineName, url: value });
      }

      return updatedAirlines;
    });
  };

  const onChangeAgency = (e, agencyName) => {
    const { value, name } = e.target;

    setAddedAgencies((prevAgencies) => {
      const updatedAgencies = [...prevAgencies];
      const index = updatedAgencies.findIndex(
        (agency) => agency.agency === agencyName
      );

      if (index !== -1) {
        updatedAgencies[index] = { agency: agencyName, url: value };
      } else {
        updatedAgencies.push({ agency: agencyName, url: value });
      }

      return updatedAgencies;
    });
  };

  const handleSave = async (data) => {
    data.preventDefault();
    try {
      setIsPending(true);
      await axios
        .patch(
          `${process.env.REACT_APP_API_URL}tours/hosts/${id}`,
          {
            price,
            region: region.value,
            country: country.value,
            url,
            phone,
            space,
            status,
            map: map_url,
            description,
            address,
            official_email,
            website,
            tourType: selectedType?.value,
            thumb_nail: file ? file : thumb_nail,
            host: selectedHosts.value,
            agencies:
              addedAgencies?.length > 0
                ? addedAgencies
                : selectedAgencies?.map((agency) => ({
                    agency: agency.value,
                    url: agency.url,
                  })),
            airlines:
              addedAirlines?.length > 0
                ? addedAirlines
                : selectedAirlines?.map((airline) => ({
                    airline: airline.value,
                    url: airline.url,
                  })),
            category: selectedCategories.map((cat) => cat.value),
            booking: {
              airbnb,
              tripeadviser,
              direct,
              bookingdotcom,
              awa,
            },
            coordinates: {
              lat: Number(lat),
              lng: Number(lng),
            },
          },
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        )
        .then((res) => {
          toast.success("Updated successfully");
          navigate(-1);
        });
    } catch (error) {
      toast.error("Could not save changes");
    } finally {
      // setSuccess(false);
      setIsPending(false);
    }
  };

  const typeOptions = [
    { value: "Experience", label: "Experience" },
    { value: "Stay", label: "Stay" },
  ];

  const hostOptions = hosts?.data?.map((u) => {
    return { value: u._id, label: u.businessName };
  });

  return (
    <form onSubmit={handleSave}>
      <div className="page-header">
        <div className="d-flex align-items-center g-4 justify-content-center">
          <Link to={"/tours"} className="text-danger">
            Go back
          </Link>
          <div className="mx-4"> || </div>
          <span>Edit Existing Tour</span>
        </div>
        <div className="ms-auto pageheader-btn">
          <button className="btn btn-primary btn-icon text-white me-2">
            <span>
              <i className="fe fe-save me-3"></i>
            </span>
            {isPending ? "Saving" : "Update"}
          </button>
        </div>
      </div>
      <div className="page">
        <div className="page-main">
          <Row>
            <Col lg={6}>
              <Card className="p-4">
                <CardHeader className="mb-4">
                  <h5 className="text-success font-bold text-lg mb-0">
                    Details
                  </h5>
                </CardHeader>

                <div className="form-group">
                  <label className="text-2xl">Tour/Space name</label>
                  <input
                    type="text"
                    placeholder="space name"
                    className="form-control"
                    value={space}
                    onChange={(e) => setSpace(e.target.value)}
                  />
                </div>
                {selectedCategories.find((vid) => vid.value === "Videos") ? (
                  <div className="form-group">
                    <label className="text-2xl">Thumb Nail ID</label>
                    <input
                      type="text"
                      placeholder="Thumb_nail ID"
                      className="form-control"
                      value={file}
                      onChange={(e) =>
                        setFile(
                          `https://i.ytimg.com/vi/${e.target.value}/maxresdefault.jpg`
                        )
                      }
                    />
                  </div>
                ) : (
                  <div className="my-1">
                    <label className="text-2xl">Thumb Nail</label>
                    <div className="w-100">
                      <img src={thumb_nail} alt="" height={100} />
                      <FileUploader
                        handleChange={handleChange}
                        name="file"
                        classes="upload-file"
                        // types={fileTypes}
                      />
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <label className="text-2xl">Description</label>
                  <textarea
                    rows="3"
                    cols="5"
                    placeholder="Write a brief description for this tour!"
                    className="form-control"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label className="text-2xl">Ratings</label>
                  <input
                    type="text"
                    placeholder="Rating"
                    className="form-control"
                    value={ratings}
                    onChange={(e) => setRatings(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label className="text-2xl">Physical Address</label>
                  <textarea
                    rows="2"
                    cols="5"
                    placeholder="What is the physical address?"
                    className="form-control"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label className="text-2xl">Tour/Space Url</label>
                  <input
                    // type="url"
                    placeholder="Space url"
                    className="form-control"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label className="text-2xl">Latitude</label>
                  <input
                    type="text"
                    placeholder="eg: 10.2839398"
                    className="form-control"
                    value={lat}
                    onChange={(e) => setLat(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label className="text-2xl">Longtitude</label>
                  <input
                    type="text"
                    placeholder="eg: -5.583939"
                    className="form-control"
                    value={lng}
                    onChange={(e) => setLng(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label className="text-2xl">Map Url</label>
                  <input
                    placeholder="Map url"
                    className="form-control"
                    value={map_url}
                    onChange={(e) => setMapUrl(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label className="text-2xl">Price</label>
                  <input
                    type="number"
                    className="form-control"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
              </Card>
            </Col>

            <Col lg={6}>
              <Card className="p-4">
                <CardHeader>
                  <h3>Attributes</h3>
                </CardHeader>
                <div className="form-group">
                  <label className="text-2xl">Select Type Of Tour</label>
                  <Select
                    components={animatedComponents}
                    isMulti={false}
                    name="tourType"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect
                    options={typeOptions}
                    value={selectedType}
                    onChange={(e) => setSelectedType(e)}
                  />
                </div>
                <div className="form-group">
                  <label className="text-2xl">Select a Category</label>
                  <Select
                    components={animatedComponents}
                    isMulti
                    name="users"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    options={categoriesOptions}
                    value={selectedCategories}
                    onChange={(e) => setSelectedCategories(e)}
                    disabled={true}
                  />
                </div>

                <div className="form-group">
                  <label className="text-2xl">Select a Country</label>
                  <Select
                    components={animatedComponents}
                    isMulti={false}
                    name="users"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    options={countries?.data?.map((country) => ({
                      label: country?.name,
                      value: country?._id,
                    }))}
                    value={country}
                    onChange={(e) => setCountry(e)}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label className="text-2xl">Region</label>
                  <Select
                    components={animatedComponents}
                    isMulti={false}
                    name="users"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    options={regions?.data?.map((region) => ({
                      label: region?.name,
                      value: region?._id,
                    }))}
                    value={region}
                    onChange={(e) => setRegion(e)}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label className="text-2xl">Status</label>
                  <select
                    className="form-select"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option selected>{status}</option>

                    {status !== "Offline" && status !== "Online" && (
                      <>
                        <option value="Online">Online</option>
                        <option value="Offline">Offline</option>
                      </>
                    )}
                    {status === "Offline" && (
                      <option value="Online">Online</option>
                    )}
                    {status === "Online" && (
                      <option value="Offline">Offline</option>
                    )}
                  </select>
                </div>
                <div className="form-group">
                  <label className="text-2xl">Ownership</label>
                  <Select
                    components={animatedComponents}
                    isMulti={false}
                    name="hosts"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    options={hostOptions}
                    value={selectedHosts}
                    onChange={(e) => setSelectedHosts(e)}
                  />
                </div>
              </Card>
              <Card className="p-4">
                <CardHeader className="mb-4">
                  <h3 className="text-success font-bold text-lg mb-0">
                    Direct Booking
                  </h3>
                </CardHeader>
                <div className="form-group">
                  <label className="text-2xl">Email</label>
                  <input
                    // type="url"
                    placeholder="email eg:example@domain.com"
                    className="form-control"
                    value={official_email}
                    onChange={(e) => setOfficialEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label className="text-2xl">Phone</label>
                  <input
                    placeholder="Phone number"
                    className="form-control"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label className="text-2xl">Direct booking URL</label>
                  <input
                    // type="url"
                    placeholder="Website url"
                    className="form-control"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </div>
              </Card>
              <Card className="card p-4">
                <CardHeader>
                  <h3 className="text-info">3rd Party Booking</h3>
                </CardHeader>
                <div className="form-group">
                  <label className="text-2xl">Airlines</label>

                  <Select
                    components={animatedComponents}
                    isMulti
                    name="airlines"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    options={airlines?.data?.map((airlines) => ({
                      label: airlines?.name,
                      value: airlines?._id,
                      url: airlines?.url,
                    }))}
                    value={selectedAirlines}
                    onChange={(e) => setSelectedAirlines(e)}
                  />
                </div>
                {selectedAirlines?.map((airline) => (
                  <div className="my-2">
                    <label>{airline?.label} URL</label>
                    <input
                      className="form-control"
                      type="text"
                      name={airline?.url}
                      defaultValue={airline?.url}
                      onChange={(e) => onChangeAirline(e, airline?.value)}
                    />
                  </div>
                ))}
                <div className="form-group">
                  <label className="text-2xl">OTAs</label>

                  <Select
                    components={animatedComponents}
                    isMulti
                    name="agencies"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    options={agencies?.data?.map((agency) => ({
                      label: agency?.name,
                      value: agency?._id,
                      url: agency?.url,
                    }))}
                    value={selectedAgencies}
                    onChange={(e) => setSelectedAgencies(e)}
                  />
                </div>
                {selectedAgencies?.map((agency) => (
                  <div className="my-2">
                    <label>{agency?.label} URL</label>
                    <input
                      className="form-control"
                      type="text"
                      name={agency?.value}
                      defaultValue={agency?.url}
                      // onBlur={onChangeAgency}
                      onChange={(e) => onChangeAgency(e, agency?.value)}
                    />
                  </div>
                ))}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </form>
  );
};

export default EditTour;

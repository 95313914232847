import React, { useEffect, useState } from "react";
import Table from "../../components/tables/Table";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner } from "reactstrap";
import AddAgencyModal from "../../components/agencies/AddAgencyModal";
import { toast } from "react-toastify";
import {
  deleteAgency,
  getAgencies,
  reset,
} from "../../redux/agencies/agency.slice";
import DeleteModal from "../../components/common/DeleteModal";
import EditAgencyModal from "../../components/agencies/EditAgencyModal";
import DetailsAgencyModal from "../../components/agencies/DetailsAgencyModal";
import { getCountries } from "../../redux/countries/country.slice";

const OTAgencies = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [isDelete, setisDelete] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [isDetails, setIsDetails] = useState(false);
  const [agency, setAgency] = useState(null);

  const { agencies, loading, isSuccess } = useSelector(
    (state) => state.agencies
  );
  const { countries } = useSelector((state) => state.countries);

  useEffect(() => {
    dispatch(getAgencies({}));
    dispatch(getCountries({}));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(reset());
      toast.success("Success");
      setShowForm(false);
      setisDelete(false);
      dispatch(getAgencies({}));
    }
  }, [dispatch, isSuccess]);

  const handleDeleteagency = () => {
    dispatch(deleteAgency(agency[0]));
  };

  return (
    <>
      <div className="page-header">
        <div></div>
        <div className="ms-auto pageheader-btn">
          <Button onClick={() => setShowForm(!showForm)}>Add Agency</Button>
        </div>
      </div>
      <div className="page mt-6">
        <div className="page-main">
          <div className="card">
            {loading ? (
              <div className=" h-97 mx-auto d-flex justify-content-center align-items-center">
                <Spinner size={"lg"} />
              </div>
            ) : (
              <Table
                data={agencies?.data?.map((agency) => ({
                  _id: agency?._id,
                  name: agency?.name,
                  url: agency?.url,
                  country: agency?.country?.name,
                  countryId: agency?.country?._id,
                  icon: agency?.icon,
                }))}
                title={`All Travel Agencies : ${agencies?.count}`}
                // navLink="/agencies/"
                columns={[
                  { name: "_id", label: "ID", options: { display: false } },
                  { name: "name", label: "AGENCY NAME" },
                  { name: "country", label: "COUNTRY" },
                  { name: "url", label: "AGENCY URL" },
                  { name: "icon", label: "ICON", options: { display: false } },
                  {
                    name: "countryId",
                    label: "CountryId",
                    options: { display: false },
                  },
                  {
                    name: "Actions",
                    options: {
                      filter: true,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                          <div className="d-flex g-4">
                            <i
                              onClick={() => {
                                setAgency((prev) => tableMeta.rowData);
                                setIsDetails(!isDetails);
                              }}
                              className="ri-eye-line btn display-6 cursor-pointer text-info"
                            ></i>
                            <i
                              onClick={() => {
                                setAgency((prev) => tableMeta.rowData);
                                setisEdit(!isEdit);
                              }}
                              className="ri-edit-box-line btn display-6 cursor-pointer text-primary"
                            ></i>
                            <i
                              onClick={() => {
                                setAgency(tableMeta.rowData);
                                setisDelete(!isDelete);
                              }}
                              className="ri-delete-bin-4-line btn display-6 cursor-pointer text-danger"
                            ></i>
                          </div>
                        );
                      },
                    },
                  },
                ]}
              />
            )}
          </div>
        </div>
      </div>
      <AddAgencyModal
        show={showForm}
        countries={countries?.data}
        onCloseClick={() => setShowForm(!showForm)}
      />
      {agency && (
        <EditAgencyModal
          show={isEdit}
          agency={
            agency && {
              _id: agency[0],
              name: agency[1],
              country: agency[2],
              url: agency[3],
              icon: agency[4],
            }
          }
          onCloseClick={() => {
            setAgency(null);
            setisEdit(!isEdit);
          }}
        />
      )}
      {agency && (
        <DetailsAgencyModal
          show={isDetails}
          agency={
            agency && {
              _id: agency[0],
              name: agency[1],
              country: agency[2],
              url: agency[3],
              icon: agency[4],
            }
          }
          onCloseClick={() => {
            setAgency(null);
            setIsDetails(!isDetails);
          }}
        />
      )}
      {agency && (
        <DeleteModal
          show={isDelete}
          title={`Are you sure ${agency && agency[1]}?`}
          onCloseClick={() => setisDelete(false)}
          onDeleteClick={handleDeleteagency}
        />
      )}
    </>
  );
};

export default OTAgencies;

import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "bookings/";

//add booking
export const addBooking = async (booking, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL, booking, config);
  return response.data;
};

//get bookings
export const getBookings = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "me", config);
  return response.data;
};

//delete booking
export const deleteBooking = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(`${API_URL}/${id}`, config);
  return response.data;
};

const bookingService = {
  addBooking,
  getBookings,
  deleteBooking,
};

export default bookingService;

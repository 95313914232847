import React from "react";

const Settings = () => {
  return (
    <>
      <div className="page">
        <div className="page-main">
          <div className="card mt-4">
            <h1>Setting here</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;

const TheFooter = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center flex-row-reverse">
          <div className="col-md-12 col-sm-12 text-center">
            Copyright ©<span id="year"></span>
            <a
              target="_blank"
              href="www.360africa.tours"
              className="ms-2 text-success"
            >
              360Africa.
            </a>{" "}
            Designed with
            <span className="fa fa-heart text-danger mx-2"></span> by
            <a target="_blank" href="www.dobiison.com">
              {" "}
              DOBIISON.
            </a>{" "}
            All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
};

export default TheFooter;

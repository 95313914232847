import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import JoditEditor from "jodit-react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";

const AddStory = () => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const [isPending, setIsPending] = useState(false);

  const editor = useRef(null);
  const [content, setContent] = useState("");
  const config = {
    readonly: false,
    height: "60vh",
  };
  const handleUpdate = (event) => {
    // const editorContent = event.target.innerHTML;
    setContent(event);
  };

  const [file, setFile] = useState(null);

  const handleChange = (file) => {
    // if (window !== undefined) {
    let formData = new FormData();
    formData.append("thumb_nail", file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post("https://api.360africa.tours/api/v1/upload", formData, config)
      .then(({ data }) => {
        setFile(data.data[0].location);
      })
      .catch((error) => toast.error(error.toString()));
    // }
  };

  const handleSend = (data) => {
    setIsPending(true);
    fetch("https://api.360africa.tours/api/v1/blogs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        category: data.category,
        title: data.title,
        thumbnail: file,
        description: content,
      }),
    })
      .then((res) => {
        setIsPending(false);
        toast.success("blog post successfully");
        return res.json();
      })
      .then(() => {
        reset({
          title: "",
          category: "",
        });
        setContent("");
        setFile(null);
      })
      .catch((error) => toast.error(error.toString()));
  };

  return (
    <>
      <div className="page-header">
        <div>
          <Link
            to={"/stories"}
            className="btn ms-md-2 btn-icon fs-20 d-flex align-items-center justify-content-center"
          >
            <span>
              <i className="fe fe-chevron-left fs-20"></i>
            </span>
            Go back
          </Link>
        </div>
        <div className="ms-auto pageheader-btn"></div>
      </div>

      <div className="card mt-5">
        <div className="container mx-auto my-4 lg:px-60">
          <label className="text-2xl">Feature Image</label>
          <div className="my-5 d-flex justify-content-between">
            <FileUploader
              handleChange={handleChange}
              name="file"
              // types={fileTypes}
            />
            <img
              src={file || "/placeholder.jpeg"}
              alt="no feature img"
              className="border"
              style={{ height: 150, width: 150 }}
            />
          </div>
          <form onSubmit={handleSubmit(handleSend)}>
            <div className="form-group mb-6">
              <label className="text-2xl">Title of story</label>
              <textarea
                className="my-4 form-control"
                name="title"
                rows="2"
                placeholder="story title"
                {...register("title", { required: true })}
              ></textarea>
            </div>
            <label className="text-2xl">Select a category</label>

            <div className="my-5">
              <select
                {...register("category", { required: true })}
                className="form-select"
              >
                <option selected>select category</option>
                <option value="Company">Company</option>
                <option value="Stays">Stays</option>
                <option value="Experiences">Experiences</option>
                <option value="Policy">Policy</option>
                <option value="Community">Community</option>
                <option value="360africa.com">360africa.com</option>
              </select>
            </div>
            <label className="text-2xl">Describe the story</label>
            <div className="my-5">
              {/* <ReactQuill
                // theme="snow"
                value={value}
                onChange={setValue}
                className="min-h-max"
              /> */}
              <JoditEditor
                ref={editor}
                value={content}
                config={config}
                // onBlur={(newContent) => setContent(newContent)}
                onChange={(newContent) => setContent(newContent)}
              />
              {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
            </div>

            <button type="submit" className="btn btn-blue my-5 px-6">
              {isPending ? <Spinner size={"sm"} /> : "Send"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddStory;

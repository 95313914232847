// import { data } from "../../data/table";
import MuiTable from "mui-datatables";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useNavigate } from "react-router-dom";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
});

const Table = ({ title, data, columns, onClick, onSelectData, navLink }) => {
  const navigate = useNavigate();

  return (
    <CacheProvider value={muiCache}>
      {/* <ThemeProvider theme={getMuiTheme()}> */}
      <MuiTable
        title={title}
        data={data}
        columns={columns}
        options={{
          // serverSide: true,
          fixedHeader: false,

          filterType: "dropdown",
          onRowClick: (rowData) =>
            navLink
              ? navigate(navLink + rowData[0])
              : () => {
                  return true;
                },
          selectableRowsHideCheckboxes: true,
        }}
      />
      {/* </ThemeProvider> */}
    </CacheProvider>
  );
};

// @ts-ignore

export default Table;

import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL + "buckets/";
// const API_URL = "http://localhost:5000/api/v1/buckets/";

const addBucket = async (bucket, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL, bucket, config);
  return response.data;
};

const getBuckets = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "me", config);
  return response.data.data;
};

const getBucketsList = async (token, name) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "me/" + name, config);
  console.log("buckets new : ", response.data.data);
  return response.data.data;
};

const deleteBucket = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(`${API_URL}${id}`, config);
  return response.data;
};

const bucketService = {
  addBucket,
  getBuckets,
  deleteBucket,
  getBucketsList,
};

export default bucketService;

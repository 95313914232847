import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { FormGroup, Modal, ModalBody, Row, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import { addCategory } from "../../redux/categories/category.slice";

const AddCategoryModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { loading, isSuccess, isError, message } = useSelector(
    (state) => state.categories
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      setImage(null);
    }
  }, [isSuccess]);

  const [file, setFile] = useState(null);

  const handleChange = (file) => {
    // if (window !== undefined) {
    let formData = new FormData();
    formData.append("thumb_nail", file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post("https://api.360africa.tours/api/v1/upload", formData, config)
      .then(({ data }) => {
        setFile(data.data[0].location);
      })
      .catch((error) => console.log(error));
    // }
  };

  const handleSave = (data) => {
    dispatch(
      addCategory({
        ...data,
        icon: file,
      })
    );
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size="md">
      <ModalBody className="py-3 px-5">
        <form onSubmit={handleSubmit(handleSave)}>
          <div className="mt-2">
            <h3 className="mb-4 text-muted mx-auto w-100 text-center">
              Add new Category
            </h3>
            <Row className="row-cols-lg-1 mt-6">
              <FormGroup>
                <label htmlFor="name">Category Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <span className="text-danger">Name is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="description">Description</label>
                <textarea
                  type="text"
                  name="description"
                  className="form-control"
                  {...register("description", { required: true })}
                ></textarea>
                {errors.description && (
                  <span className="text-danger">Description is required</span>
                )}
              </FormGroup>
              {image ? (
                <div className="upload-button" id="file-upload">
                  <img src={image} alt="" />
                </div>
              ) : (
                <FormGroup>
                  <label htmlFor="icon">Category Icon</label>

                  <div class="flex items-center justify-center w-full">
                    <FileUploader
                      handleChange={handleChange}
                      name="file"
                      // types={fileTypes}
                    />
                  </div>
                </FormGroup>
              )}
            </Row>
          </div>
          <hr className="my-4" />
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn w-sm btn-primary"
              id="delete-record"
            >
              {loading ? <Spinner size={"sm"} /> : "Save"}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

AddCategoryModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default AddCategoryModal;

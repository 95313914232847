import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bookingService from "./bookings.service";

const initialState = {
  bookings: [],
  loading: false,
  error: null,
  message: "",
};

//add booking
export const addBooking = createAsyncThunk(
  "bookings/addBooking",
  async (booking, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await bookingService.addBooking(booking, token);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

//get bookings
export const getBookings = createAsyncThunk(
  "bookings/getBookings",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      console.log(token);
      return await bookingService.getBookings(token);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

//delete booking
export const deleteBooking = createAsyncThunk(
  "bookings/deleteBooking",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.data.token;
      return await bookingService.deleteBooking(id, token);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const bookingSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {
    reset: (state) => {
      state.bookings = [];
      state.loading = false;
      state.error = null;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBooking.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addBooking.fulfilled, (state, action) => {
        state.loading = false;
        state.bookings.push(action.payload);
        state.message = "Booking added successfully";
      })
      .addCase(addBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBookings.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBookings.fulfilled, (state, action) => {
        state.loading = false;
        state.bookings = action.payload;
      })
      .addCase(getBookings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteBooking.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteBooking.fulfilled, (state, action) => {
        state.loading = false;
        state.message = "Booking deleted successfully";
      })
      .addCase(deleteBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { reset } = bookingSlice.actions;
export default bookingSlice.reducer;

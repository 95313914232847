import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";

const DetailsCategoryModal = ({ show, category, onCloseClick }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size="md">
      <ModalBody className="py-3 px-5">
        <div>
          <h1 className="text-center mb-4 font-weight-bold text-primary">
            Details Category
          </h1>
          <hr />
          <p>
            <strong>Name: </strong>
            <span className="text-muted">{category?.name}</span>
          </p>
          <hr />
          <p>
            <strong>Description: </strong>
            <span className="text-muted">{category?.description}</span>
          </p>
          <hr />
          <div>
            <p>Category Icon : </p>
            <img
              src={category?.icon}
              alt=""
              className="bg-black"
              height={100}
              width={100}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

DetailsCategoryModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  category: PropTypes.object,
};

export default DetailsCategoryModal;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import listService from "./list.service";

const initialState = {
  lists: [],
  list: {},
  loading: false,
  success: false,
  isError: false,
  message: "",
};

//add list
export const addList = createAsyncThunk(
  "list/addList",
  async (list, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await listService.addList(list, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get lists
export const getList = createAsyncThunk("list/getList", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState()?.auth?.user?.token;

    return await listService.getList(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//get a single list
export const getSingleList = createAsyncThunk(
  "list/getSingleList",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState()?.auth?.user?.token;

      return await listService.getSingleList(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update a single list
export const updateSingleList = createAsyncThunk(
  "list/updateSingleList",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState()?.auth?.user?.token;

      return await listService.updateSingleList(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete a single list
export const deleteSingleList = createAsyncThunk(
  "list/deleteSingleList",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState()?.auth?.user?.token;

      return await listService.deleteSingleList(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const listSlice = createSlice({
  name: "list",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addList.fulfilled, (state, action) => {
        console.log("payload add : ", action.payload);
        state.loading = false;
        state.success = true;
        state.lists.push(action.payload.data);
        state.message = "New bucketlist added successfully";
      })
      .addCase(addList.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.lists = action.payload;
      })
      .addCase(getList.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSingleList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSingleList.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.list = action.payload;
      })
      .addCase(getSingleList.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateSingleList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateSingleList.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.list = action.payload;
      })
      .addCase(updateSingleList.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

const { actions, reducer } = listSlice;

export const { reset } = actions;

export default reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import regionService from "./region.service";
import regionsService from "./region.service";

const initialState = {
  region: {},
  regions: [],
  loading: false,
  isSuccess: false,
  isError: false,
  message: "",
};

//add region
export const addRegion = createAsyncThunk(
  "region/addRegion",
  async (region, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const response = await regionService.addRegion(token, region);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//get countries list
export const getRegions = createAsyncThunk(
  "region/getRegions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await regionsService.getRegions();
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//get region by ID
export const getRegionByID = createAsyncThunk(
  "region/getRegionByID",
  async (id, { rejectWithValue }) => {
    try {
      const response = await regionsService.getRegionByID(id);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//update region by ID
export const updateRegionByID = createAsyncThunk(
  "region/updateregionByID",
  async (region, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const response = await regionService.updateRegionByID(
        token,
        region.id,
        region.data
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//delete region list
export const deleteregion = createAsyncThunk(
  "region/deleteRegion",
  async (id, { rejectWithValue, getState }) => {
    try {
      const response = await regionService.deleteRegion(
        id,
        getState().auth.user.token
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    reset: (state) => {
      state.region = {};
      state.loading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: {
    [addRegion.pending]: (state, action) => {
      state.loading = true;
    },
    [addRegion.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
    },
    [addRegion.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [getRegions.pending]: (state, action) => {
      state.loading = true;
    },
    [getRegions.fulfilled]: (state, action) => {
      state.regions = action.payload;
      state.loading = false;
    },
    [getRegions.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [getRegionByID.pending]: (state, action) => {
      state.loading = true;
    },
    [getRegionByID.fulfilled]: (state, action) => {
      state.region = action.payload;
      state.loading = false;
    },
    [getRegionByID.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [updateRegionByID.pending]: (state, action) => {
      state.loading = true;
    },
    [updateRegionByID.fulfilled]: (state, action) => {
      state.region = action.payload;
      state.loading = false;
      state.isSuccess = true;
    },
    [updateRegionByID.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [deleteregion.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteregion.fulfilled]: (state, action) => {
      state.region = action.payload;
      state.isSuccess = true;
      state.loading = false;
    },
    [deleteregion.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
  },
});

export default regionSlice.reducer;
export const { reset } = regionSlice.actions;

import { configureStore } from "@reduxjs/toolkit";
import toursSlice from "./tours/tours.slices";
import authSlice from "./auth/auth.slices";
import bucketSlice from "./buckets/bucket.slices";
import bookingsSlices from "./bookings/bookings.slices";
import hostSlice from "./host/host.slice";
import historySlice from "./history/history.slices";
import listSlice from "./lists/list.slices";
import toggleSlice from "./toggle/toggle.slice";
import storiesSlice from "./stories/stories.slices";
import countrySlice from "./countries/country.slice";
import regionSlice from "./regions/region.slice";
import categorySlice from "./categories/category.slice";
import userSlices from "./users/user.slices";
import airlineSlice from "./airlines/airline.slice";
import agencyService from "./agencies/agency.slice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    users: userSlices,
    tours: toursSlice,
    stories: storiesSlice,
    bucket: bucketSlice,
    booking: bookingsSlices,
    hosts: hostSlice,
    countries: countrySlice,
    regions: regionSlice,
    agencies: agencyService,
    airlines: airlineSlice,
    categories: categorySlice,
    history: historySlice,
    lists: listSlice,
    toggle: toggleSlice,
  },
});

export default store;

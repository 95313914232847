import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addNewTour, fetchTours, reset } from "../../redux/tours/tours.slices";
import { Card, CardHeader, Col, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { categoryies } from "../../data/categories";
import { getCountries } from "../../redux/countries/country.slice";
import { getRegions } from "../../redux/regions/region.slice";
import { getCategories } from "../../redux/categories/category.slice";
import { getAirlines } from "../../redux/airlines/airline.slice";
import { getAgencies } from "../../redux/agencies/agency.slice";

const animatedComponents = makeAnimated();

const AddTour = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedAirlines, setSelectedAirlines] = useState([]);
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [addedAirlines, setAddedAirlines] = useState([]);
  const [addedAgencies, setAddedAgencies] = useState([]);
  const [selectedType, setSelectedType] = useState(null);

  const typeOptions = [
    { value: "Experience", label: "Experience" },
    { value: "Stay", label: "Stay" },
  ];

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm();

  const onChangeAirline = (e, airlineName) => {
    const { value, name } = e.target;

    setAddedAirlines((prevAirlines) => {
      const updatedAirlines = [...prevAirlines];
      const index = updatedAirlines.findIndex(
        (airline) => airline.airline === airlineName
      );

      if (index !== -1) {
        updatedAirlines[index] = { airline: airlineName, url: value };
      } else {
        updatedAirlines.push({ airline: airlineName, url: value });
      }

      return updatedAirlines;
    });
  };

  const onChangeAgency = (e, agencyName) => {
    const { value, name } = e.target;

    setAddedAgencies((prevAgencies) => {
      const updatedAgencies = [...prevAgencies];
      const index = updatedAgencies.findIndex(
        (agency) => agency.agency === agencyName
      );

      if (index !== -1) {
        updatedAgencies[index] = { agency: agencyName, url: value };
      } else {
        updatedAgencies.push({ agency: agencyName, url: value });
      }

      return updatedAgencies;
    });
  };

  const [file, setFile] = useState(null);

  const handleChange = (file) => {
    // if (window !== undefined) {
    let formData = new FormData();
    formData.append("thumb_nail", file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post("https://api.360africa.tours/api/v1/upload", formData, config)
      .then(({ data }) => {
        setFile(data.data[0].location);
      })
      .catch((error) => console.log(error));
    // }
  };

  useEffect(() => {
    dispatch(fetchTours({}));
    dispatch(getRegions({}));
    dispatch(getCategories({}));
    dispatch(getAirlines({}));
    dispatch(getAgencies({}));
    dispatch(getCountries({}));
  }, []);

  const { loading, success } = useSelector((state) => state.tours);
  const { regions } = useSelector((state) => state.regions);
  const { countries } = useSelector((state) => state.countries);
  const { categories } = useSelector((state) => state.categories);
  const { airlines, loading: airlineLoading } = useSelector(
    (state) => state.airlines
  );
  const { agencies, loading: agencyLoading } = useSelector(
    (state) => state.agencies
  );

  useEffect(() => {
    if (success) {
      toast.success("Added successfully");
      dispatch(reset());
      navigate(-1);
    }
  }, [dispatch, navigate, success]);

  const handleSave = (data) => {
    if (!file) {
      return toast.error("Please select a thumbnail");
    }
    if (categoryies?.length <= 0) {
      return toast.error("Please select a category");
    }
    if (!selectedType) {
      return toast.error("Please select a tour type");
    }

    dispatch(
      addNewTour({
        ...data,
        thumb_nail: file,
        tourType: selectedType.value,
        categories: selectedCategories?.map((cat) => cat.value),
        airlines: addedAirlines,
        agencies: addedAgencies,
        coordinates: {
          lat: Number(data.lat),
          lng: Number(data.lng),
        },
      })
    );
  };

  if (agencyLoading || airlineLoading)
    return (
      <div className="text-center w-100 m-5 h-100">
        <Spinner className="m-5" />
      </div>
    );

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <div className="page-header">
        <div className="d-flex align-items-center g-4 justify-content-center">
          <Link to={"/tours"} className="text-danger">
            Go back
          </Link>
          <div className="mx-4"> || </div>
          <span>Add New Tour</span>
        </div>
        <div className="ms-auto pageheader-btn">
          <button className="btn btn-primary btn-icon text-white me-2">
            <span>
              <i className="fe fe-save me-3"></i>
            </span>
            {loading ? "Saving" : "Save"}
          </button>
        </div>
      </div>
      <div className="page">
        <div className="page-main">
          <Row>
            <Col lg={6}>
              {/* <Card className="p-4">
                <CardHeader className="mb-4">
                  <h5 className="text-info">Type & Category</h5>
                </CardHeader>
               
              </Card> */}

              <Card className="p-4">
                <CardHeader className="mb-4">
                  <h3 className="text-success">Details</h3>
                </CardHeader>
                <div className="form-group">
                  <label className="text-2xl">Space name</label>
                  <input
                    type="text"
                    placeholder="space name"
                    className="form-control"
                    {...register("space", { required: true })}
                  />
                  {errors.space && (
                    <span className="text-danger">Space Name is required</span>
                  )}
                </div>
                {selectedCategories.find((vid) => vid.value === "Videos") ? (
                  <div className="form-group">
                    <label className="text-2xl">Thumb Nail ID</label>
                    <input
                      type="text"
                      placeholder="Thumb_nail ID"
                      className="form-control"
                      value={file}
                      onChange={(e) =>
                        setFile(
                          `https://i.ytimg.com/vi/${e.target.value}/maxresdefault.jpg`
                        )
                      }
                    />
                  </div>
                ) : (
                  <div className="my-1">
                    <label className="text-2xl">Thumb Nail</label>

                    <FileUploader
                      handleChange={handleChange}
                      name="file"
                      // types={fileTypes}
                    />
                  </div>
                )}
                <div className="form-group">
                  <label className="text-2xl">Description</label>
                  <textarea
                    rows="3"
                    cols="5"
                    placeholder="Write a brief description for this tour!"
                    className="form-control"
                    {...register("description", { required: true })}
                  ></textarea>
                  {errors.description && (
                    <span className="text-danger">Description is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label className="text-2xl">Ratings</label>
                  <input
                    type="text"
                    placeholder="Rating"
                    className="form-control"
                    {...register("rating", { required: true })}
                  />
                  {errors.rating && (
                    <span className="text-danger">Rating is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label className="text-2xl">Physical Address</label>
                  <textarea
                    rows="2"
                    cols="5"
                    placeholder="What is the physical address?"
                    className="form-control"
                    {...register("address", { required: true })}
                  ></textarea>
                  {errors.address && (
                    <span className="text-danger">Address is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label className="text-2xl">Tour/Space Url</label>
                  <input
                    // type="url"
                    placeholder="Space url"
                    className="form-control"
                    {...register("url", { required: true })}
                  />
                  {errors.url && (
                    <span className="text-danger">Space Url is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label className="text-2xl">Latitude Coordinate</label>
                  <input
                    type="text"
                    placeholder="eg: 10.2839398"
                    className="form-control"
                    {...register("lat", { required: true })}
                  />
                </div>
                <div className="form-group">
                  <label className="text-2xl">Longtitude coordinate</label>
                  <input
                    type="text"
                    placeholder="eg: -5.583939"
                    className="form-control"
                    {...register("lng", { required: true })}
                  />
                </div>
                <div className="form-group">
                  <label className="text-2xl">Google Maps Url</label>
                  <input
                    // type="url"
                    placeholder="Map url"
                    className="form-control"
                    {...register("map")}
                  />
                </div>
                <div className="form-group">
                  <label className="text-2xl">Price</label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("price", { required: true })}
                  />
                  {errors.price && (
                    <span className="text-danger">Price is required</span>
                  )}
                </div>
              </Card>
            </Col>
            <Col lg={6}>
              <Card className="card p-4">
                <CardHeader>
                  <h3>Attributes</h3>
                </CardHeader>
                <div className="form-group">
                  <label className="text-2xl">Select Type Of Tour</label>
                  <Select
                    components={animatedComponents}
                    isMulti={false}
                    name="tourType"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect
                    options={typeOptions}
                    value={selectedType}
                    onChange={(e) => setSelectedType(e)}
                  />
                </div>
                <div className="form-group">
                  <label className="text-2xl">Select a Category</label>
                  <Select
                    components={animatedComponents}
                    isMulti
                    name="users"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    options={categories?.data?.map((cat) => ({
                      label: cat?.name,
                      value: cat?._id,
                    }))}
                    value={selectedCategories}
                    onChange={(e) => setSelectedCategories(e)}
                  />
                </div>

                <div className="form-group">
                  <label className="text-2xl">Select a Country</label>
                  <select
                    {...register("country", { required: true })}
                    className="form-select"
                  >
                    <option selected>select country</option>
                    {countries?.data?.map((country) => (
                      <option key={country._id} value={country?._id}>
                        {country?.name}
                      </option>
                    ))}
                  </select>
                  {errors.country && (
                    <span className="text-danger">Country is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label className="text-2xl">Region</label>

                  <select
                    placeholder="Region"
                    className="form-select"
                    {...register("region", { required: true })}
                  >
                    <option selected>select region</option>
                    {regions?.data?.map((reg) => (
                      <option key={reg._id} value={reg?._id}>
                        {reg?.name}
                      </option>
                    ))}
                  </select>
                  {errors.region && (
                    <span className="text-danger">Region is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label className="text-2xl">Status</label>
                  <select
                    {...register("status", { required: true })}
                    className="form-select"
                  >
                    <option value="Online" selected>
                      Online
                    </option>
                    <option value="Offline">Offline</option>
                  </select>
                  {errors.status && (
                    <span className="text-danger">Status is required</span>
                  )}
                </div>
              </Card>

              <Card className="p-4">
                <CardHeader className="mb-4">
                  <h3 className="text-danger">Direct Booking</h3>
                </CardHeader>
                <div className="form-group">
                  <label className="text-2xl">Email</label>
                  <input
                    // type="url"
                    placeholder="email eg:example@domain.com"
                    className="form-control"
                    {...register("official_email", { required: true })}
                  />
                  {errors.official_email && (
                    <span className="text-danger">Email is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label className="text-2xl">Phone</label>
                  <input
                    // type="tel"
                    placeholder="Phone number"
                    className="form-control"
                    {...register("phone", { required: true })}
                  />
                  {errors.phone && (
                    <span className="text-danger">Phone is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label className="text-2xl">Direct booking URL</label>
                  <input
                    // type="url"
                    placeholder="direct booking url"
                    className="form-control"
                    {...register("website", { required: true })}
                  />
                  {errors.url && (
                    <span className="text-danger">Booking Url is required</span>
                  )}
                </div>
              </Card>
              <Card className="card p-4">
                <CardHeader>
                  <h3 className="text-info">3rd Party Booking</h3>
                </CardHeader>
                <div className="form-group">
                  <label className="text-2xl">Airlines</label>

                  <Select
                    components={animatedComponents}
                    isMulti
                    name="airlines"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    options={airlines?.data?.map((airlines) => ({
                      label: airlines?.name,
                      value: airlines?._id,
                    }))}
                    value={selectedAirlines}
                    onChange={(e) => setSelectedAirlines(e)}
                  />
                </div>
                {selectedAirlines?.map((airline) => (
                  <div className="my-2">
                    <label>{airline?.label} URL</label>
                    <input
                      className="form-control"
                      type="text"
                      name={airline?.value}
                      onChange={(e) => onChangeAirline(e, airline?.value)}
                    />
                  </div>
                ))}
                <div className="form-group">
                  <label className="text-2xl">OTAs</label>

                  <Select
                    components={animatedComponents}
                    isMulti
                    name="agencies"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    options={agencies?.data?.map((agency) => ({
                      label: agency?.name,
                      value: agency?._id,
                    }))}
                    value={selectedAgencies}
                    onChange={(e) => setSelectedAgencies(e)}
                  />
                </div>
                {selectedAgencies?.map((agency) => (
                  <div className="my-2">
                    <label>{agency?.label} URL</label>
                    <input
                      className="form-control"
                      type="text"
                      name={agency?.value}
                      // onBlur={onChangeAgency}
                      onChange={(e) => onChangeAgency(e, agency?.value)}
                    />
                  </div>
                ))}
              </Card>
              {/* 
              <Card className="p-4">
                <div className="form-group">
                  <label className="text-2xl">Airbnb</label>
                  <input
                    // type="url"
                    placeholder="url"
                    className="form-control"
                    {...register("airbnb")}
                  />
                </div>
                <div className="form-group">
                  <label className="text-2xl">Booking.Com</label>
                  <input
                    // type="url"
                    placeholder="url"
                    className="form-control"
                    {...register("bookingdotcom")}
                  />
                </div>
                <div className="form-group">
                  {watch("country") === "Ghana" ? (
                    <label className="text-2xl">AWA</label>
                  ) : (
                    <label className="text-2xl">Jambojet</label>
                  )}

                  <input
                    // type="url"
                    placeholder="url"
                    className="form-control"
                    {...register("awa")}
                  />
                </div>

                <div className="form-group">
                  <label className="text-2xl">Travel Adviser</label>
                  <input
                    // type="url"
                    placeholder="url"
                    className="form-control"
                    {...register("tripadvisor")}
                  />
                </div>

              </Card> */}
            </Col>
          </Row>
        </div>
      </div>
    </form>
  );
};

export default AddTour;

import axios from "axios";

// const API_URL = "http://localhost:5000/api/v1/";
const API_URL = process.env.REACT_APP_API_URL;

//add country

const addCountry = async (token, country) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "countries", country, config);
  return response.data?.data;
};

//get country list
const getCountries = async () => {
  const response = await axios.get(API_URL + "countries");
  return response.data;
};

//get Country by ID

const getCountryByID = async (id) => {
  const response = await axios.get(API_URL + "countries/" + id);
  return response.data?.data;
};

//update country by ID

const updateCountryByID = async (token, id, country) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.patch(
    API_URL + "countries/" + id,
    country,
    config
  );
  return response.data?.data;
};

//delete a contry
const deleteCountry = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + "countries/" + id, config);
  return response.data;
};

const countriesService = {
  addCountry,
  getCountries,
  getCountryByID,
  updateCountryByID,
  deleteCountry,
};

export default countriesService;

import React, { useEffect, useState } from "react";
import Table from "../../components/tables/Table";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteTourById,
  fetchTours,
  reset,
} from "../../redux/tours/tours.slices";
import { Badge, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import DeleteModal from "../../components/common/DeleteModal";
import { toast } from "react-toastify";

const ToursList = () => {
  const dispatch = useDispatch();
  const [tour, setTour] = useState(null);
  const [isDelete, setisDelete] = useState(false);

  const { tours, isSuccess, loading } = useSelector((state) => state.tours);

  useEffect(() => {
    dispatch(fetchTours({}));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(reset());
      toast.success("Success");
      setisDelete(false);
      dispatch(fetchTours({}));
    }
  }, [dispatch, isSuccess]);

  const handleDeleteTour = () => {
    dispatch(deleteTourById(tour[0]));
  };

  return (
    <>
      <div className="page-header">
        <div></div>
        <div className="ms-auto pageheader-btn">
          <Link
            to={"/tours/add"}
            className="btn btn-success btn-icon text-white me-2"
          >
            <span>
              <i className="fe fe-currency"></i>
            </span>
            Add New Tour
          </Link>
        </div>
      </div>
      <div className="page">
        <div className="page-main">
          <div className="card">
            {loading ? (
              <div className=" h-97 mx-auto d-flex justify-content-center align-items-center">
                <Spinner size={"lg"} />
              </div>
            ) : (
              <Table
                data={tours?.map((t) => ({
                  _id: t._id,
                  space: t.space,
                  country: t.country?.name,
                  rating: t.rating,
                  status: (
                    <Badge color={t.status === "Online" ? "success" : "danger"}>
                      {t.status}
                    </Badge>
                  ),
                  views: t.views,
                  url: (
                    <a
                      target="_blank"
                      href={`https://www.360africa.tours/tours/${t?._id}`}
                    >
                      {`https://www.360africa.tours/tours/${t?._id}`}
                    </a>
                  ),
                  region: t.region?.name,
                }))}
                title={`All Tours : ${tours?.length}`}
                // navLink="/tours/"
                columns={[
                  { name: "_id", label: "ID", options: { display: false } },
                  { name: "space", label: "NAME" },
                  { name: "country", label: "COUNTRY" },
                  { name: "region", label: "REGION" },
                  { name: "status", label: "STATUS" },
                  { name: "views", label: "VISIT" },
                  { name: "url", label: "SPACE URL" },
                  // { name: "rating", label: "RATING" },
                  {
                    name: "Actions",
                    options: {
                      filter: true,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                          <div className="d-flex">
                            <Link
                              to={`/tours/${tableMeta.rowData[0]}`}
                              className="btn text-info"
                            >
                              <i className="ri-eye-line display-6"></i>
                            </Link>
                            <Link
                              to={`/tours/edit/${tableMeta.rowData[0]}`}
                              className="btn text-success"
                            >
                              <i className="ri-edit-box-line display-6"></i>
                            </Link>
                            <i
                              onClick={() => {
                                setTour(tableMeta.rowData);
                                setisDelete(!isDelete);
                              }}
                              className="ri-delete-bin-4-line btn display-6 cursor-pointer text-danger"
                            ></i>
                          </div>
                        );
                      },
                    },
                  },
                  // { name: "thumb_nail", label: "IMAGE" },
                ]}
              />
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        show={isDelete}
        title={`Are you sure ${tour && tour[1]}?`}
        onCloseClick={() => setisDelete(false)}
        onDeleteClick={handleDeleteTour}
      />
    </>
  );
};

export default ToursList;

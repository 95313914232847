import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import categoryService from "./category.service";

const initialState = {
  category: {},
  categories: [],
  loading: false,
  isSuccess: false,
  isError: false,
  message: "",
};

//add category
export const addCategory = createAsyncThunk(
  "category/addCategory",
  async (category, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const response = await categoryService.addCategory(token, category);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//get categories list
export const getCategories = createAsyncThunk(
  "category/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await categoryService.getCategories();
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//get category by ID
export const getCategoryByID = createAsyncThunk(
  "category/getCategoryByID",
  async (id, { rejectWithValue }) => {
    try {
      const response = await categoryService.getCategoryByID(id);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//update category by ID
export const updateCategoryByID = createAsyncThunk(
  "category/updateCategoryByID",
  async (category, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const response = await categoryService.updateCategoryByID(
        token,
        category.id,
        category.data
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//delete category list
export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async (id, { rejectWithValue, getState }) => {
    try {
      const response = await categoryService.deleteCategory(
        id,
        getState().auth.user.token
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    reset: (state) => {
      state.category = {};
      state.loading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: {
    [addCategory.pending]: (state, action) => {
      state.loading = true;
    },
    [addCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
    },
    [addCategory.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [getCategories.pending]: (state, action) => {
      state.loading = true;
    },
    [getCategories.fulfilled]: (state, action) => {
      state.categories = action.payload;
      state.loading = false;
    },
    [getCategories.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [getCategoryByID.pending]: (state, action) => {
      state.loading = true;
    },
    [getCategoryByID.fulfilled]: (state, action) => {
      state.category = action.payload;
      state.loading = false;
    },
    [getCategoryByID.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [updateCategoryByID.pending]: (state, action) => {
      state.loading = true;
    },
    [updateCategoryByID.fulfilled]: (state, action) => {
      state.category = action.payload;
      state.loading = false;
      state.isSuccess = true;
    },
    [updateCategoryByID.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [deleteCategory.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCategory.fulfilled]: (state, action) => {
      state.category = action.payload;
      state.isSuccess = true;
      state.loading = false;
    },
    [deleteCategory.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
  },
});

export default categorySlice.reducer;
export const { reset } = categorySlice.actions;

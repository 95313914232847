import axios from "axios";

// const API_URL = "http://localhost:5000/api/v1/";
const API_URL = process.env.REACT_APP_API_URL;

//add region

const addRegion = async (token, region) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "regions", region, config);
  return response.data?.data;
};

//get region list
const getRegions = async () => {
  const response = await axios.get(API_URL + "regions");
  return response.data;
};

//get region by ID

const getRegionByID = async (id) => {
  const response = await axios.get(API_URL + "regions/" + id);
  return response.data?.data;
};

//update region by ID

const updateRegionByID = async (token, id, region) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.patch(API_URL + "regions/" + id, region, config);
  return response.data?.data;
};

//delete a contry
const deleteRegion = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + "regions/" + id, config);
  return response.data;
};

const regionsService = {
  addRegion,
  getRegions,
  getRegionByID,
  updateRegionByID,
  deleteRegion,
};

export default regionsService;

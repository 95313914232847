const _nav = [
  {
    label: "Dashboard",
    _tag: "CSidebarNavDropdown",
    name: "Home",
    route: "/dashboard",
    icon: "side-menu__icon fe fe-grid",
  },
  {
    label: "Tours",
    _tag: "CSidebarNavDropdown",
    name: "Tours",
    route: "/tours",
    icon: "side-menu__icon fe fe-home",
  },
  {
    label: "Countries",
    _tag: "CSidebarNavDropdown",
    name: "Countries",
    route: "/countries",
    icon: "side-menu__icon fe fe-globe",
  },
  {
    label: "Regions",
    _tag: "CSidebarNavDropdown",
    name: "Regions",
    route: "/regions",
    icon: "side-menu__icon fe fe-map",
  },
  {
    label: "Categories",
    _tag: "CSidebarNavDropdown",
    name: "Categories",
    route: "/categories",
    icon: "side-menu__icon fe fe-list",
  },
  {
    label: "Partners",
    _tag: "CSidebarNavDropdown",
    name: "Partners",
    route: "/partners",
    icon: "side-menu__icon fe fe-user-plus",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Airlines",
        to: "/airlines",
      },
      {
        _tag: "CSidebarNavItem",
        name: "OTAS",
        to: "/otas",
      },
    ],
  },
  {
    label: "Blog & Stories",
    _tag: "CSidebarNavDropdown",
    name: "Stories",
    route: "/stories",
    icon: "side-menu__icon fe fe-briefcase",
  },
  {
    label: "Hosts",
    _tag: "CSidebarNavDropdown",
    name: "Hosts",
    route: "/hosts",
    icon: "side-menu__icon fe fe-menu",
  },
  {
    label: "Users",
    _tag: "CSidebarNavDropdown",
    name: "Users",
    route: "/users",
    icon: "side-menu__icon fe fe-users",
  },

  // {
  //   label: "Settings & Others",
  //   _tag: "CSidebarNavDropdown",
  //   name: "Settings",
  //   route: "/settings",
  //   icon: "side-menu__icon fe fe-settings",
  // },
];

export default _nav;

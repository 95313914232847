import { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

function AddTransactions({ isOpen, toggle }) {
  const dispatch = useDispatch();

  const [tType, setTType] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleSend = (data) => {
    // dispatch(addTransaction({ tType, ...data }));
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader className="text-secondary" toggle={toggle}>
          New Transaction
        </ModalHeader>
        <ModalBody className="row">
          <form onSubmit={handleSubmit(handleSend)}>
            <FormGroup className="col-md-12">
              <label htmlFor="exampleSelect">Kind of Transaction</label>
              <br />
              <input
                type="radio"
                name="tType"
                value={"income"}
                onChange={(e) => setTType(e.target.value)}
                className="mx-3"
                // {...register("type", { required: true })}
              />
              Income
              <input
                type="radio"
                name="tType"
                value={"expense"}
                onChange={(e) => setTType(e.target.value)}
                className="mx-3"
                // {...register("type", { required: true })}
              />
              Expense
            </FormGroup>

            {tType === "income" && (
              <FormGroup className="col-md-12">
                <label htmlFor="exampleSelect">Income type</label>
                <select
                  className="form-control"
                  {...register("type", { required: true })}
                >
                  <option value="">select Income type</option>
                  <option value="Credit">Credit</option>
                  <option value="Cash">Cash</option>
                </select>
                {errors.type && (
                  <p className="text-danger"> Transaction type is required</p>
                )}
              </FormGroup>
            )}

            {tType === "expense" && (
              <FormGroup className="col-md-12">
                <label htmlFor="exampleSelect">Expense type</label>
                <select
                  className="form-control"
                  {...register("type", { required: true })}
                >
                  <option value="">select expense type</option>
                  <option value="Capital">Capital</option>
                  <option value="Production">Cost of Production</option>
                  <option value="S & D">Selling & Distribution</option>
                </select>
                {errors.type && (
                  <p className="text-danger"> Expense type is required</p>
                )}
              </FormGroup>
            )}

            <FormGroup className="col-md-12">
              <label htmlFor="title">Transaction Title</label>
              <input
                className="form-control"
                {...register("title", { required: true })}
              />
              {errors.title && (
                <p className="text-danger"> Transaction title is required</p>
              )}
            </FormGroup>
            <FormGroup className="col-md-12">
              <label htmlFor="title">Transaction Amount</label>
              <input
                className="form-control"
                {...register("amount", { required: true })}
                type="number"
              />
              {errors.amount && (
                <p className="text-danger"> Transaction amount is required</p>
              )}
            </FormGroup>
            <FormGroup className="col-md-12">
              <label htmlFor="description">Transaction Description</label>
              <textarea
                className="form-control"
                {...register("description", { required: true })}
              ></textarea>
              {errors.description && (
                <p className="text-danger"> Description is required</p>
              )}
            </FormGroup>
            <FormGroup className="col-md-12">
              <label htmlFor="date">Transaction's Date</label>
              <input
                className="form-control"
                {...register("date", { required: true })}
                type="date"
              />
              {errors.date && (
                <p className="text-danger"> Transaction date is required</p>
              )}
            </FormGroup>
            {/* <FormGroup className="col-md-12">
              <label htmlFor="name">Farmer's Email</label>
              <Input name="farmer" type="time" />
            </FormGroup> */}
            <ModalFooter>
              <Button type="submit" color="primary">
                Save
              </Button>
              <Button color="ghost" onClick={toggle}>
                Close
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default AddTransactions;

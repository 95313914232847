import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL + "list/";
// const API_URL = "http://localhost:5000/api/v1/list/";

const addList = async (history, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL, history, config);
  return response.data;
};

const getList = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "me", config);
  return response.data.data;
};

const getSingleList = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "find?id=" + id, {}, config);
  return response.data.data;
};

const updateSingleList = async (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.patch(API_URL + data.id, data.data, config);
  return response.data.data;
};

const deleteSingleList = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(API_URL + id, config);
  return response.data;
};

const listService = {
  addList,
  getList,
  getSingleList,
  deleteSingleList,
  updateSingleList,
};

export default listService;

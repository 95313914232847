import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row, Spinner } from "reactstrap";
import moment from "moment/moment";

import { fetchStoryById } from "../../redux/stories/stories.slices";
import DeleteModal from "../../components/common/DeleteModal";

export default function StoryDetails() {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { id } = useParams();

  const { story, loading } = useSelector((state) => state.stories);

  useEffect(() => {
    dispatch(fetchStoryById(id));
  }, []);

  return (
    <>
      <div className="page-header">
        <div>
          <Link to={"/stories"} className="text-danger me-4">
            Go back
          </Link>{" "}
          || <span className="ms-4">{story._id}</span>
        </div>
        <div className="ms-auto pageheader-btn">
          <Link
            to={"/stories/add"}
            className="btn btn-outline-primary ms-md-2 btn-icon"
          >
            <span>
              <i className="fe fe-user"></i>
            </span>
            Edit
          </Link>
          <button
            className="btn btn-outline-danger ms-md-2 btn-icon"
            onClick={() => setShowDeleteModal(!showDeleteModal)}
          >
            <span>
              <i className="fe fe-dustbin"></i>
            </span>
            Delete
          </button>
        </div>
      </div>
      <div className="page">
        <div className="page-main">
          {loading ? (
            <div className=" h-97 mx-auto d-flex justify-content-center align-items-center">
              <Spinner size={"lg"} />
            </div>
          ) : (
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <div>
                  <h2 className="my-3 text-primary">{story.title}</h2>
                  <p>
                    Created Date :{" "}
                    <span className="badge badge-danger-light">
                      {moment(story.createdAt).format("MMMM Do, YYYY")}
                    </span>
                  </p>
                </div>
                <img
                  src={story.thumbnail || "/placeholder.jpeg"}
                  alt="no feature img"
                  className="border"
                  style={{ height: 150, width: 230 }}
                />
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="4">
                    <h4>Story ID</h4>
                    <p className="text-muted">{story._id}</p>
                  </Col>
                  <Col lg="4">
                    <h4>Author</h4>
                    <p className="text-muted">{story.author}</p>
                  </Col>
                  <Col lg="4">
                    <h4>Category</h4>
                    <p className="text-muted">{story.category}</p>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <h4>Body</h4>
                  <div
                    className="text-muted"
                    dangerouslySetInnerHTML={{ __html: story.description }}
                  ></div>
                </Row>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onCloseClick={() => setShowDeleteModal(!showDeleteModal)}
      />
    </>
  );
}

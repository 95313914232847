import axios from "axios";

// const API_URL = "http://localhost:5000/api/v1/";
const API_URL = process.env.REACT_APP_API_URL;

//add Airline

const addAirline = async (token, airline) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "airlines", airline, config);
  return response.data?.data;
};

//get Airline list
const getAirlines = async () => {
  const response = await axios.get(API_URL + "airlines");
  return response.data;
};

//get Airline by ID

const getAirlineByID = async (id) => {
  const response = await axios.get(API_URL + "airlines/" + id);
  return response.data?.data;
};

//update Airline by ID

const updateAirlineByID = async (token, id, airline) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.patch(
    API_URL + "airlines/" + id,
    airline,
    config
  );
  return response.data?.data;
};

//delete a contry
const deleteAirline = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + "airlines/" + id, config);
  return response.data;
};

const airlinesService = {
  addAirline,
  getAirlines,
  getAirlineByID,
  updateAirlineByID,
  deleteAirline,
};

export default airlinesService;

import { Outlet } from "react-router-dom";
import { TheFooter, TheSidebar, TheHeader, TheNotifications } from "./index";

const TheLayout = () => {
  return (
    <div className="page">
      <TheSidebar />
      <TheHeader />
      <div className="page-main">
        <div className="main-content app-content top-50">
          <div className="side-app">
            <div className="main-container container-fluid">
              {/* <div className='card p-5'> */}
              <Outlet />
              {/* </div> */}
            </div>
          </div>
        </div>
        <TheNotifications />
        <TheFooter />
      </div>
    </div>
  );
};

export default TheLayout;

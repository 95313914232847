import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";

const DetailsAgencyModal = ({ show, agency, onCloseClick }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size="md">
      <ModalBody className="py-3 px-5">
        <div>
          <h1 className="text-center mb-4 font-weight-bold text-primary">
            Details Of Agency
          </h1>
          <hr />
          <p>
            <strong>Name: </strong>
            {agency?.name}
          </p>
          <hr />
          <p>
            <strong>Country Name: </strong>
            {agency?.country}
          </p>
          <hr />
          <p>
            <strong>agency Url: </strong>
            {agency?.url}
          </p>
          <hr />
          <div>
            <p>Logo : </p>
            <img
              src={agency?.icon}
              alt=""
              className="bg-black"
              height={100}
              width={100}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

DetailsAgencyModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  agency: PropTypes.any,
};

export default DetailsAgencyModal;

import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL + "history/";
// const API_URL = "http://localhost:5000/api/v1/history/";

const addHistory = async (history, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL, history, config);
  return response.data;
};

const getHistories = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "me", config);
  return response.data.data;
};

const bucketService = {
  addHistory,
  getHistories,
};

export default bucketService;

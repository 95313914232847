import React, { useEffect, useState } from "react";
import Table from "../../components/tables/Table";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner } from "reactstrap";
import {
  deleteCategory,
  getCategories,
  reset,
} from "../../redux/categories/category.slice";
import { toast } from "react-toastify";
import DeleteModal from "../../components/common/DeleteModal";
import AddCategoryModal from "../../components/categories/AddCategoryModal";
import DetailsCategoryModal from "../../components/categories/DetailsCategoryModal";
import EditCategoryModal from "../../components/categories/EditCategoryModal";

const CategoriesList = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [isDelete, setisDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDetails, setIsDetails] = useState(false);
  const [category, setCategory] = useState(null);

  const { categories, loading, isSuccess } = useSelector(
    (state) => state.categories
  );

  useEffect(() => {
    dispatch(getCategories({}));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(reset());
      toast.success("Success");
      setShowForm(false);
      setisDelete(false);
      dispatch(getCategories({}));
    }
  }, [dispatch, isSuccess]);

  const handleDeleteCategory = () => {
    dispatch(deleteCategory(category[0]));
  };

  return (
    <>
      <div className="page-header">
        <div></div>
        <div className="ms-auto pageheader-btn">
          <Button onClick={() => setShowForm(!showForm)}>Add Category</Button>
        </div>
      </div>
      <div className="page mt-6">
        <div className="page-main">
          <div className="card">
            {loading ? (
              <div className=" h-97 mx-auto d-flex justify-content-center align-items-center">
                <Spinner size={"lg"} />
              </div>
            ) : (
              <Table
                data={categories?.data}
                title={`All Categories : ${categories?.count}`}
                // navLink="/categories/"
                columns={[
                  { name: "_id", label: "ID", options: { display: false } },

                  { name: "name", label: "NAME" },
                  { name: "description", label: "DESCRIPTON" },
                  { name: "icon", label: "Image", options: { display: false } },
                  {
                    name: "Actions",
                    options: {
                      filter: true,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                          <div className="d-flex g-4">
                            <i
                              onClick={() => {
                                setCategory(tableMeta.rowData);
                                setIsDetails(!isDetails);
                              }}
                              className="ri-eye-line btn display-6 cursor-pointer text-info"
                            ></i>
                            <i
                              onClick={() => {
                                setCategory(tableMeta.rowData);
                                setIsEdit(!isEdit);
                              }}
                              className="ri-edit-box-line btn display-6 cursor-pointer text-primary"
                            ></i>
                            <i
                              onClick={() => {
                                setCategory(tableMeta.rowData);
                                setisDelete(!isDelete);
                              }}
                              className="ri-delete-bin-4-line btn display-6 cursor-pointer text-danger"
                            ></i>
                          </div>
                        );
                      },
                    },
                  },
                ]}
              />
            )}
          </div>
        </div>
      </div>
      <AddCategoryModal
        show={showForm}
        onCloseClick={() => setShowForm(!showForm)}
      />
      {category && (
        <DetailsCategoryModal
          show={isDetails}
          category={
            category && {
              name: category[1],
              description: category[2],
              icon: category[3],
            }
          }
          onCloseClick={() => {
            setCategory(null);
            setIsDetails(!isDetails);
          }}
        />
      )}
      {category && (
        <EditCategoryModal
          show={isEdit}
          category={
            category && {
              _id: category[0],
              name: category[1],
              description: category[2],
              icon: category[3],
            }
          }
          onCloseClick={() => {
            setCategory(null);
            setIsEdit(!isEdit);
          }}
        />
      )}
      <DeleteModal
        show={isDelete}
        title={`Are you sure ${category && category[1]}?`}
        onCloseClick={() => {
          setCategory(null);
          setisDelete(false);
        }}
        onDeleteClick={handleDeleteCategory}
      />
    </>
  );
};

export default CategoriesList;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isRegister: false,
  isLogin: false,
  isForgot: false,
  isReset: false,
  isLoading: false,
  isError: false,
  isSuccess: false,
};

// slice for toggle

const toggleSlice = createSlice({
  name: "toggle",
  initialState,
  reducers: {
    toggleLogin: (state) => {
      state.isLogin = !state.isLogin;
      state.isRegister = false;
      state.isForgot = false;
    },
    toggleRegister: (state) => {
      state.isRegister = !state.isRegister;
      state.isLogin = false;
      state.isForgot = false;
    },
    toggleForgot: (state) => {
      state.isForgot = !state.isForgot;
      state.isLogin = false;
      state.isRegister = false;
    },
    toggleReset: (state) => {
      state = initialState;
    },
  },
});

export const { toggleLogin, toggleRegister, toggleForgot, toggleReset } =
  toggleSlice.actions;
export default toggleSlice.reducer;

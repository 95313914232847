import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import agencyService from "./agency.service";

const initialState = {
  agency: {},
  agencies: [],
  loading: false,
  isSuccess: false,
  isError: false,
  message: "",
};

//add agency
export const addAgency = createAsyncThunk(
  "agency/addAgency",
  async (agency, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const response = await agencyService.addAgency(token, agency);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//get agencies list
export const getAgencies = createAsyncThunk(
  "agency/getAgencies",
  async (_, { rejectWithValue }) => {
    try {
      const response = await agencyService.getAgencies();
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//get agency by ID
export const getAgencyByID = createAsyncThunk(
  "agency/getAgencyByID",
  async (id, { rejectWithValue }) => {
    try {
      const response = await agencyService.getAgencyByID(id);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//update agency by ID
export const updateAgencyByID = createAsyncThunk(
  "agency/updateAgencyByID",
  async (agency, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const response = await agencyService.updateAgencyByID(
        token,
        agency.id,
        agency.data
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//delete agency list
export const deleteAgency = createAsyncThunk(
  "agency/deleteAgency",
  async (id, { rejectWithValue, getState }) => {
    try {
      const response = await agencyService.deleteAgency(
        id,
        getState().auth.user.token
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

const agencieslice = createSlice({
  name: "agency",
  initialState,
  reducers: {
    reset: (state) => {
      state.agency = {};
      state.loading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: {
    [addAgency.pending]: (state, action) => {
      state.loading = true;
    },
    [addAgency.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
    },
    [addAgency.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [getAgencies.pending]: (state, action) => {
      state.loading = true;
    },
    [getAgencies.fulfilled]: (state, action) => {
      state.agencies = action.payload;
      state.loading = false;
    },
    [getAgencies.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [getAgencyByID.pending]: (state, action) => {
      state.loading = true;
    },
    [getAgencyByID.fulfilled]: (state, action) => {
      state.agency = action.payload;
      state.loading = false;
    },
    [getAgencyByID.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [updateAgencyByID.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAgencyByID.fulfilled]: (state, action) => {
      state.agency = action.payload;
      state.loading = false;
      state.isSuccess = true;
    },
    [updateAgencyByID.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [deleteAgency.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAgency.fulfilled]: (state, action) => {
      state.agency = action.payload;
      state.isSuccess = true;
      state.loading = false;
    },
    [deleteAgency.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
  },
});

export default agencieslice.reducer;
export const { reset } = agencieslice.actions;

import axios from "axios";

// const API_URL = "http://localhost:5000/api/v1/";
const API_URL = process.env.REACT_APP_API_URL;

//add Category

const addCategory = async (token, category) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "categories", category, config);
  return response.data?.data;
};

//get Category list
const getCategories = async () => {
  const response = await axios.get(API_URL + "categories");
  return response.data;
};

//get Category by ID

const getCategoryByID = async (id) => {
  const response = await axios.get(API_URL + "categories/" + id);
  return response.data?.data;
};

//update Category by ID

const updateCategoryByID = async (token, id, category) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.patch(
    API_URL + "categories/" + id,
    category,
    config
  );
  return response.data?.data;
};

//delete a contry
const deleteCategory = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + "categories/" + id, config);
  return response.data;
};

const categoriesService = {
  addCategory,
  getCategories,
  getCategoryByID,
  updateCategoryByID,
  deleteCategory,
};

export default categoriesService;

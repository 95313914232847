import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// const APP_URL = "http://localhost:5000/api/v1/tours/";
const APP_URL = process.env.REACT_APP_API_URL + "tours/";

//create a mew tour
export const addNewTour = createAsyncThunk(
  "tours/addTour",
  async (tour, { getState }) => {
    try {
      const token = getState().auth.user.token;
      const { data } = await axios.post(`${APP_URL}add`, tour, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      return error.message;
    }
  }
);

//get a tour by id
export const fetchTourById = createAsyncThunk(
  "tours/fetchTourById",
  async (id, { getState }) => {
    try {
      const { data } = await axios.get(`${APP_URL}${id}`);
      return data;
    } catch (error) {
      return error.message;
    }
  }
);

//get a tour by id
export const deleteTourById = createAsyncThunk(
  "tours/deleteTourById",
  async (id, { getState }) => {
    try {
      const token = getState().auth.user.token;
      const { data } = await axios.delete(`${APP_URL}${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      return error.message;
    }
  }
);

//create a tour review
export const createTourReview = createAsyncThunk(
  "tours/createTourReview",
  async (review, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const { data } = await axios.post(
        `${APP_URL}${review.id}/reviews`,
        {
          comment: review.comment,
          rating: review.rating,
        },
        { headers }
      );
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return rejectWithValue(message);
    }
  }
);

//create a tour review
export const addToBucket = createAsyncThunk(
  "tours/addToBucket",
  async (tour, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const { data } = await axios.post(
        `${APP_URL}${tour._id}/buckets`,
        {},
        { headers }
      );
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return rejectWithValue(message);
    }
  }
);

//get all tours
export const fetchTours = createAsyncThunk(
  "tours/fetchAllTours",
  async (__, { getState }) => {
    try {
      const token = getState().auth.user.token;

      const { data } = await axios.get(`${APP_URL}all/admin`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // const tours = await response.json();
      return data.data;
    } catch (error) {
      return error.message;
    }
  }
);

//get all tours
export const fetchToursMonthlyViews = createAsyncThunk(
  "tours/fetchAllToursMonthlyViews",
  async (__, { getState }) => {
    try {
      const token = getState().auth.user.token;

      const { data } = await axios.get(`${APP_URL}monthly-views/admin`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // const tours = await response.json();
      return data;
    } catch (error) {
      return error.message;
    }
  }
);

//create slice

const toursSlice = createSlice({
  name: "tours",
  initialState: {
    loading: false,
    error: null,
    tours: [],
    views: [],
    message: "",
    tour: {},
    bucket: {},
    buckets: [],
    success: false,
  },
  reducers: {
    reset: (state) => {
      state.tours = [];
      state.views = [];
      state.loading = false;
      state.error = null;
      state.message = "";
      state.tour = {};
      state.bucket = {};
      state.buckets = [];
      state.success = false;
    },
  },
  extraReducers: {
    [addNewTour.pending]: (state, action) => {
      state.loading = true;
    },
    [addNewTour.fulfilled]: (state, action) => {
      state.loading = false;
      state.tours = action.payload;
      state.success = true;
      // console.log("action res", action.payload);
    },
    [addNewTour.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    [fetchTours.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTours.fulfilled]: (state, action) => {
      state.loading = false;
      state.tours = action.payload;
      // console.log("action res", action.payload);
    },
    [fetchTours.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchToursMonthlyViews.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchToursMonthlyViews.fulfilled]: (state, action) => {
      state.loading = false;
      state.views = action.payload;
      // console.log("action res", action.payload);
    },
    [fetchToursMonthlyViews.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchTourById.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTourById.fulfilled]: (state, action) => {
      state.loading = false;
      state.tour = action.payload;
    },
    [fetchTourById.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.message = action.payload;
    },
    [deleteTourById.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteTourById.fulfilled]: (state, action) => {
      state.loading = false;
      state.tour = action.payload;
      state.success = true;
    },
    [deleteTourById.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.message = action.payload;
    },
    [createTourReview.pending]: (state, action) => {
      state.loading = true;
    },
    [createTourReview.fulfilled]: (state, action) => {
      state.loading = false;
      state.tour = action.payload;
      state.success = true;
    },
    [createTourReview.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.message = action.payload;
    },
    [addToBucket.pending]: (state, action) => {
      state.loading = true;
    },
    [addToBucket.fulfilled]: (state, action) => {
      state.loading = false;
      state.bucket = action.payload;
      state.buckets = [...state.buckets, action.payload];
      state.success = true;
    },
    [addToBucket.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.message = action.payload;
    },
  },
});
export const { reset } = toursSlice.actions;
export default toursSlice.reducer;

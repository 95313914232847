import React, { useEffect } from "react";
import Table from "../../components/tables/Table";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import { getHost } from "../../redux/host/host.slice";

const HostList = () => {
  const dispatch = useDispatch();

  const { hosts, loading } = useSelector((state) => state.hosts);

  useEffect(() => {
    dispatch(getHost({}));
  }, []);

  return (
    <>
      {/* <div className="page-header">
        <div></div>
        <div className="ms-auto pageheader-btn"></div>
      </div> */}
      <div className="page mt-6">
        <div className="page-main">
          <div className="card">
            {loading ? (
              <div className=" h-97 mx-auto d-flex justify-content-center align-items-center">
                <Spinner size={"lg"} />
              </div>
            ) : (
              <Table
                data={hosts?.data}
                title={`All Hosts : ${hosts?.count}`}
                navLink="/hosts/"
                columns={[
                  { name: "_id", label: "ID", options: { display: false } },
                  { name: "businessName", label: "ORGANIZATION" },
                  { name: "name", label: "CONTACT PERSON" },
                  { name: "occupation", label: "ROLE" },
                  { name: "phoneNumber", label: "PHONE NUMBER" },
                  { name: "email", label: "EMAIL" },
                  { name: "status", label: "STATUS" },
                ]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HostList;

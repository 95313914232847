import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bucketService from "./bucket.service";

const initialState = {
  buckets: [],
  bucket: {},
  bucketItems: [],
  loading: false,
  success: false,
  isError: false,
  message: "",
};

//add bucket
export const addBucket = createAsyncThunk(
  "buckets/addBucket",
  async (bucket, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await bucketService.addBucket(bucket, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get buckets
export const getBuckets = createAsyncThunk(
  "buckets/getBuckets",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState()?.auth?.user?.token;

      return await bucketService.getBuckets(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get buckets
export const getBucketsList = createAsyncThunk(
  "buckets/getBucketsList",
  async (name, thunkAPI) => {
    try {
      const token = thunkAPI.getState()?.auth?.user?.token;

      return await bucketService.getBucketsList(token, name);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete bucket
export const deleteBucket = createAsyncThunk(
  "buckets/deleteBucket",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await bucketService.deleteBucket(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const bucketSlice = createSlice({
  name: "buckets",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBucket.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addBucket.fulfilled, (state, action) => {
        console.log("payload add : ", action.payload);
        state.loading = false;
        state.success = true;
        state.buckets.push(action.payload.data);
        state.message = "Bucket added successfully";
      })
      .addCase(addBucket.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getBuckets.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBuckets.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.buckets = action.payload;
      })
      .addCase(getBuckets.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getBucketsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBucketsList.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.bucketItems = action.payload;
      })
      .addCase(getBucketsList.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteBucket.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteBucket.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.buckets = state.buckets.filter(
          (bucket) => bucket._id !== action.payload.data._id
        );
        state.message = "Bucket deleted successfully";
      })
      .addCase(deleteBucket.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

const { actions, reducer } = bucketSlice;

export const { reset } = actions;

export default reducer;

import axios from "axios";

// const API_URL = "http://localhost:5000/api/v1/";
const API_URL = process.env.REACT_APP_API_URL;

//add Agency

const addAgency = async (token, agency) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "agencies", agency, config);
  return response.data?.data;
};

//get Agency list
const getAgencies = async () => {
  const response = await axios.get(API_URL + "agencies");
  return response.data;
};

//get Agency by ID

const getAgencyByID = async (id) => {
  const response = await axios.get(API_URL + "agencies/" + id);
  return response.data?.data;
};

//update Agency by ID

const updateAgencyByID = async (token, id, agency) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.patch(
    API_URL + "agencies/" + id,
    agency,
    config
  );
  return response.data?.data;
};

//delete a contry
const deleteAgency = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + "agencies/" + id, config);
  return response.data;
};

const agenciesService = {
  addAgency,
  getAgencies,
  getAgencyByID,
  updateAgencyByID,
  deleteAgency,
};

export default agenciesService;

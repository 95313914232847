import React, { useEffect, useState } from "react";
import Table from "../../components/tables/Table";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner } from "reactstrap";
import {
  deleteAirline,
  getAirlines,
  reset,
  updateAirlineByID,
} from "../../redux/airlines/airline.slice";
import AddAirlineModal from "../../components/airlines/AddAirlineModal";
import { toast } from "react-toastify";
import DeleteModal from "../../components/common/DeleteModal";
import EditAirlineModal from "../../components/airlines/EditAirlineModal";
import DetailsAirlineModal from "../../components/airlines/DetailsAirlineModal";
import { getCountries } from "../../redux/countries/country.slice";

const AirlinesList = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [isDelete, setisDelete] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [isDetails, setIsDetails] = useState(false);
  const [airline, setAirline] = useState(null);

  const { airlines, loading, isSuccess } = useSelector(
    (state) => state.airlines
  );

  useEffect(() => {
    dispatch(getCountries({}));
    dispatch(getAirlines({}));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(reset());
      toast.success("Success");
      setShowForm(false);
      setisDelete(false);
      dispatch(getAirlines({}));
    }
  }, [dispatch, isSuccess]);

  const handleDeleteairline = () => {
    dispatch(deleteAirline(airline[0]));
  };

  const handleEditairline = () => {
    dispatch(updateAirlineByID(airline[0]));
  };

  return (
    <>
      <div className="page-header">
        <div></div>
        <div className="ms-auto pageheader-btn">
          <Button onClick={() => setShowForm(!showForm)}>Add Airline</Button>
        </div>
      </div>
      <div className="page mt-6">
        <div className="page-main">
          <div className="card">
            {loading ? (
              <div className=" h-97 mx-auto d-flex justify-content-center align-items-center">
                <Spinner size={"lg"} />
              </div>
            ) : (
              <Table
                data={airlines?.data?.map((airline) => ({
                  _id: airline?._id,
                  name: airline?.name,
                  url: airline?.url,
                  country: airline?.country?.name,
                  countryId: airline?.country?._id,
                  icon: airline?.icon,
                }))}
                title={`All Airlines: ${airlines?.count}`}
                // navLink="/airlines/"
                columns={[
                  { name: "_id", label: "ID", options: { display: false } },
                  { name: "name", label: "AIRLINE NAME" },
                  { name: "country", label: "COUNTRY" },
                  { name: "url", label: "AIRLINE URL" },
                  { name: "icon", label: "ICON", options: { display: false } },
                  {
                    name: "Actions",
                    options: {
                      filter: true,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                          <div className="d-flex g-4">
                            <i
                              onClick={() => {
                                setAirline((prev) => tableMeta.rowData);
                                setIsDetails(!isDetails);
                              }}
                              className="ri-eye-line btn display-6 cursor-pointer text-info"
                            ></i>
                            <i
                              onClick={() => {
                                setAirline((prev) => tableMeta.rowData);
                                setisEdit(!isEdit);
                              }}
                              className="ri-edit-box-line btn display-6 cursor-pointer text-primary"
                            ></i>
                            <i
                              onClick={() => {
                                setAirline(tableMeta.rowData);
                                setisDelete(!isDelete);
                              }}
                              className="ri-delete-bin-4-line btn display-6 cursor-pointer text-danger"
                            ></i>
                          </div>
                        );
                      },
                    },
                  },
                ]}
              />
            )}
          </div>
        </div>
      </div>
      <AddAirlineModal
        show={showForm}
        onCloseClick={() => setShowForm(!showForm)}
        // onDeleteClick={handleDeleteairline}
      />
      {airline && (
        <EditAirlineModal
          show={isEdit}
          airline={
            airline && {
              _id: airline[0],
              name: airline[1],
              country: airline[2],
              url: airline[3],
              icon: airline[4],
            }
          }
          onCloseClick={() => {
            setAirline(null);
            setisEdit(!isEdit);
          }}
          onDeleteClick={handleEditairline}
        />
      )}
      {airline && (
        <DetailsAirlineModal
          show={isDetails}
          airline={
            airline && {
              name: airline[1],
              country: airline[2],
              url: airline[3],
              icon: airline[4],
            }
          }
          onCloseClick={() => {
            setAirline(null);
            setIsDetails(!isDetails);
          }}
        />
      )}
      {airline && (
        <DeleteModal
          show={isDelete}
          title={`Are you sure ${airline && airline[1]}?`}
          onCloseClick={() => {
            setAirline(null);
            setisDelete(false);
          }}
          onDeleteClick={handleDeleteairline}
        />
      )}
    </>
  );
};

export default AirlinesList;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteTourById,
  fetchTourById,
  reset,
} from "../../redux/tours/tours.slices";
import { Badge, Card, Spinner } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import airbnb from "../../assets/brands/airbnb.png";
import bookingdotcom from "../../assets/brands/bookingdotcom.png";
import awa from "../../assets/brands/awa.png";
import tripadvisor from "../../assets/brands/tripadvisor.png";
import globalIcon from "../../assets/brands/globalization.png";
import jamboo from "../../assets/brands/jamboo.png";
import phoneIcon from "../../assets/brands/phone.png";
import { toast } from "react-toastify";

const TourDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const { tour, success, loading } = useSelector((state) => state.tours);

  useEffect(() => {
    dispatch(fetchTourById(id));
  }, []);

  useEffect(() => {
    if (success) {
      toast.success("Deleted successfully");
      dispatch(reset());
      navigate(-1);
    }
  }, [dispatch, navigate, success]);

  const handleDelete = () => {
    dispatch(deleteTourById(id));
  };

  return (
    <>
      {loading ? (
        <div className="h-280 mx-auto d-flex justify-content-center align-items-center">
          <Spinner size={"lg"} />
        </div>
      ) : (
        <>
          <div className="page-header">
            <div className="d-flex align-items-center g-4 justify-content-center">
              <Link to={"/tours"} className="text-danger">
                Go back
              </Link>
              <div className="mx-4"> || </div>
              <span>{tour?._id}</span>
            </div>
            <div className="ms-auto pageheader-btn">
              <Link
                to={`/tours/edit/${tour?._id}`}
                className="btn btn-success btn-icon text-white me-2"
              >
                <span>
                  <i className="fe fe-currency"></i>
                </span>
                Edit
              </Link>
              <button
                onClick={handleDelete}
                className="btn btn-outline-danger me-2"
              >
                <span>
                  <i className="fe fe-bin"></i>
                </span>
                Delete
              </button>
            </div>
          </div>
          <div className="page">
            <div className="page-main">
              <div class="card">
                <img
                  class=" p-5"
                  src={tour?.thumb_nail}
                  alt={tour?.space}
                  height={300}
                  width={450}
                />
                <div class="card-body">
                  <h3 class="card-title">{tour?.space}</h3>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <p class="card-text">
                      <p>Status</p>
                      <span className="badge badge-danger-light">
                        {tour?.status}
                      </span>
                    </p>
                    <p class="card-text">
                      <p>Country</p>
                      <span className="badge badge-success-light">
                        {tour?.country?.name}
                      </span>
                    </p>
                    <p class="card-text">
                      <p>Region</p>
                      <span className="badge badge-orange-light">
                        {tour?.region?.name}
                      </span>
                    </p>
                    <p class="card-text">
                      <p>Rating</p>
                      <span className="badge badge-success-light">
                        {tour?.rating}
                      </span>
                    </p>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <p class="card-text">
                      <p>Categories</p>

                      {tour?.category?.map((i) => (
                        <Badge color="primary" className="me-2">
                          {i}
                        </Badge>
                      ))}
                    </p>
                    <p class="card-text">
                      <p>Coordinates</p>
                      <div>
                        <Badge color="dark" className="me-2">
                          {tour?.coordinates?.lat}
                        </Badge>
                        <Badge color="dark">{tour?.coordinates?.lng}</Badge>
                      </div>
                    </p>
                    <p class="card-text">
                      <p>Phone</p>
                      <Badge color="info">{tour?.phone}</Badge>
                    </p>
                    <p class="card-text">
                      <p>Number Of Reviews</p>
                      <span className="badge badge-success-light">
                        {tour?.numReviews}
                      </span>
                    </p>
                  </div>
                  <h3 className="my-2 bg-gray-dark-lightest p-2">
                    Booking details
                  </h3>

                  <div className="booking-container">
                    <div className="d-flex align-items-center direct-container">
                      <div
                        className={`col m-2 ms-md-0 ${
                          tour?.phone === "N/A" && "btn-disabled"
                        }`}
                      >
                        <a
                          href={`tel:${tour?.phone}`}
                          className="text-decoration-none text-black"
                        >
                          <Card className="d-flex align-items-center flex-row hover p-3">
                            <img src={phoneIcon} alt="Call Now" height={"20"} />
                            <span className="ms-3">Call</span>
                          </Card>
                        </a>
                      </div>
                      <div
                        className={`col m-2 me-md-0 ${
                          tour?.booking?.direct === "N/A" && "btn-disabled"
                        }`}
                      >
                        <a
                          target={"_blank"}
                          href={tour?.website}
                          className="text-decoration-none text-black"
                        >
                          <Card className="d-flex align-items-center flex-row p-3">
                            <img
                              src={globalIcon}
                              alt="Visit site"
                              height={"20"}
                              className="text-white bg-white"
                            />
                            <span className="ms-3"> Website</span>
                          </Card>
                        </a>
                      </div>
                    </div>
                    <hr />
                    <div className="best-deals-container">
                      <div className="d-flex">
                        <div
                          className={`col m-2 ms-md-0 ${
                            tour?.booking?.bookingdotcom === "N/A" &&
                            "btn-disabled"
                          }`}
                        >
                          <a
                            target={"_blank"}
                            href={tour?.booking?.bookingdotcom}
                            className="text-decoration-none text-black"
                          >
                            <Card className="d-flex align-items-center flex-row p-3">
                              <img
                                src={bookingdotcom}
                                alt="Booking Dot Com"
                                height={25}
                                // width={30}
                              />
                              <div className="ms-4">Booking.com</div>
                            </Card>
                          </a>
                        </div>
                        <div
                          className={`col m-2 me-md-0 ${
                            tour?.booking?.airbnb === "N/A" && "btn-disabled"
                          }`}
                        >
                          <a
                            target={"_blank"}
                            href={tour?.booking?.airbnb}
                            className="text-decoration-none text-black"
                          >
                            <Card className="d-flex align-items-center flex-row p-3 ">
                              <img src={airbnb} alt="airbnb" height={25} />
                              <div className="ms-4">Airbnb</div>
                            </Card>
                          </a>
                        </div>
                      </div>
                      <div className={`d-flex`}>
                        <div className={`col m-2 ms-md-0  btn-disabled`}>
                          <a
                            target={"_blank"}
                            href="https://www.tripadvisor.com/"
                            className="text-decoration-none text-black"
                          >
                            <Card className="d-flex align-items-center flex-row p-3 ">
                              <img
                                src={tripadvisor}
                                alt="Trip Advisor"
                                height={25}
                              />
                              <div className="ms-4">Trip Advisor</div>
                            </Card>
                          </a>
                        </div>
                        <div
                          className={`col m-2 me-md-0 ${
                            tour?.booking?.awa === "N/A" && "btn-disabled"
                          }`}
                        >
                          {tour.country === "Ghana" ? (
                            <a
                              target={"_blank"}
                              href={tour?.booking?.awa}
                              className="text-decoration-none text-black"
                            >
                              <Card className="d-flex align-items-center flex-row p-3 ">
                                <img
                                  src={awa}
                                  alt="Fly africa world"
                                  height={25}
                                />
                                <div className="ms-4">Book flight</div>
                              </Card>
                            </a>
                          ) : (
                            <a
                              target={"_blank"}
                              rel="noreferrer"
                              href={tour?.booking?.awa}
                              className="text-decoration-none text-black"
                            >
                              <Card className="d-flex align-items-center flex-row p-3 ">
                                <img
                                  src={jamboo}
                                  alt="Fly africa world"
                                  width={"45"}
                                  height={9}
                                  className="my-2"
                                />
                                <div className="ms-4">Book flight</div>
                              </Card>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TourDetails;

import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Alert,
  Spinner,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { login, reset } from "../../redux/auth/auth.slices";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { user, isError, message, isLoading } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (user && user?.token && user?.isAdmin) {
      navigate("/dashboard");
      dispatch(reset());
    }
    if (isError) {
      setTimeout(() => {
        dispatch(reset());
      }, 3000);
    }
  }, [isError, user, navigate, dispatch]);

  const onSubmit = (data) => dispatch(login(data));

  return (
    <React.Fragment>
      {/* <ParticlesAuth> */}
      <div className="auth-page-content min-vh-100 py-auto">
        <Container className="my-auto">
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-2 text-dark-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img
                      src="/assets/images/brand/360africa.png"
                      alt=""
                      className="h-80 my-6"
                      height={80}
                    />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-danger">Welcome Back !</h5>
                    <p className="text-muted">
                      Sign in to continue to 360Africa Dashboard.
                    </p>
                  </div>
                  {isError && message ? (
                    <Alert color="danger"> {message} </Alert>
                  ) : null}
                  <div className="p-2 mt-4">
                    <form onSubmit={handleSubmit(onSubmit)} action="#">
                      <div className="mb-3">
                        <Label htmlFor="email" className="form-label">
                          Email
                        </Label>
                        <input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          {...register("email", { required: true })}
                        />
                      </div>
                      {errors.email && (
                        <span className="text-danger">Email is required</span>
                      )}
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="password-input">
                          Password
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                            name="password"
                            type={visible ? "text" : "password"}
                            className="form-control pe-5"
                            placeholder="Enter Password"
                            {...register("password", { required: true })}
                          />
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            onClick={() => setVisible(!visible)}
                          >
                            {visible ? (
                              <i className="ri-eye-fill align-middle"></i>
                            ) : (
                              <i className="ri-eye-off-line align-middle"></i>
                            )}
                          </button>
                        </div>
                        {errors.password && (
                          <span className="text-danger">
                            Password is required
                          </span>
                        )}
                      </div>
                      <div className="d-flex align-items-center w-100 justify-content-between">
                        <div className="form-check float-start">
                          <div>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              {...register("remember_me")}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Remember me
                            </Label>
                          </div>
                        </div>
                        <div className="float-end">
                          <Link to="/reset" className="text-muted">
                            Forgot password?
                          </Link>
                        </div>
                      </div>

                      <div className="mt-5">
                        <button className="btn btn-success w-100" type="submit">
                          {isLoading ? <Spinner size={"sm"} /> : "Sign In"}
                        </button>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* </ParticlesAuth> */}
    </React.Fragment>
  );
};

export default Login;

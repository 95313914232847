import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import Table from "../../components/tables/Table";
import { fetchAllstories } from "../../redux/stories/stories.slices";

const StoriesList = () => {
  const dispatch = useDispatch();

  const { storiesList, loading } = useSelector((state) => state.stories);

  useEffect(() => {
    dispatch(fetchAllstories({}));
  }, []);

  return (
    <>
      <div className="page-header">
        <div></div>
        <div className="ms-auto pageheader-btn">
          <Link
            to={"/stories/add"}
            className="btn btn-outline-success ms-md-2 btn-icon"
          >
            <span>
              <i className="fe fe-user"></i>
            </span>
            New Story
          </Link>
        </div>
      </div>
      <div className="page">
        <div className="page-main">
          <div className="card">
            {loading ? (
              <div className=" h-97 mx-auto d-flex justify-content-center align-items-center">
                <Spinner size={"lg"} />
              </div>
            ) : (
              <Table
                data={storiesList}
                title={`All Stories : ${storiesList?.length}`}
                navLink="/stories/"
                columns={[
                  { name: "_id", label: "ID",options:{display:false} },
                  { name: "title", label: "TITLE" },
                  { name: "author", label: "AUTHOR" },
                  { name: "category", label: "CATEGORY" },
                  { name: "createdAt", label: "DATE ADDED" },
                ]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StoriesList;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import hostService from "./host.service";

const initialState = {
  host: {},
  hosts: [],
  loading: false,
  isSuccess: false,
  isError: false,
  message: "",
};

//get host list
export const getHost = createAsyncThunk(
  "host/getHost",
  async (_, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const response = await hostService.getHost(token);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//get host by ID
export const getHostByID = createAsyncThunk(
  "host/getHostByID",
  async (id, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const response = await hostService.getHostByID(token, id);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//update host by ID
export const updateHostByID = createAsyncThunk(
  "host/updateHostByID",
  async (host, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const response = await hostService.updateHostByID(
        token,
        host.id,
        host.data
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//delete host list
export const deleteHost = createAsyncThunk(
  "host/deleteHost",
  async (id, { rejectWithValue, getState }) => {
    try {
      const response = await hostService.deleteHost(
        id,
        getState().auth.user.token
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

const hostSlice = createSlice({
  name: "host",
  initialState,
  reducers: {
    reset: (state) => {
      state.host = {};
      state.loading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: {
    [getHost.pending]: (state, action) => {
      state.loading = true;
    },
    [getHost.fulfilled]: (state, action) => {
      state.hosts = action.payload;
      state.loading = false;
    },
    [getHost.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [getHostByID.pending]: (state, action) => {
      state.loading = true;
    },
    [getHostByID.fulfilled]: (state, action) => {
      state.host = action.payload;
      state.loading = false;
    },
    [getHostByID.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [updateHostByID.pending]: (state, action) => {
      state.loading = true;
    },
    [updateHostByID.fulfilled]: (state, action) => {
      state.host = action.payload;
      state.loading = false;
      state.isSuccess = true;
    },
    [updateHostByID.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [deleteHost.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteHost.fulfilled]: (state, action) => {
      state.host = action.payload;
      state.loading = false;
    },
    [deleteHost.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
  },
});

export default hostSlice.reducer;
export const { reset } = hostSlice.actions;

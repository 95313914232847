import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Alert,
  Spinner,
} from "reactstrap";
// import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useForm } from "react-hook-form";

import { reset, resetPassword } from "../../redux/auth/auth.slices";
import { toast } from "react-toastify";

function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  const [visible, setVisible] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { user, isError, message, isSuccess, isLoading } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isSuccess) {
      navigate("/login");
      dispatch(reset());
    }
    if (isError) {
      setTimeout(() => {
        dispatch(reset());
      }, 3000);
    }
  }, [navigate, isError, user, isSuccess, dispatch]);

  const onSubmit = (data) => {
    if (data.new_password !== data.confirm_password) {
      return toast.error("Passsword mismatched");
    } else {
      dispatch(
        resetPassword({
          token,
          ...data,
        })
      );
    }
  };

  return (
    <React.Fragment>
      {/* <ParticlesAuth> */}
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-5 text-dark-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img
                      src="/assets/images/brand/360africa.png"
                      alt=""
                      className="h-80 my-6"
                      height={80}
                    />
                  </Link>
                </div>
                <p className="mt-3 fs-15 fw-medium">
                  360africa Admin Dashboard
                </p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm={10} md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-danger">Reset Password !</h5>
                    <p className="text-muted">
                      Reset to continue to 360africa Management.
                    </p>
                  </div>
                  {isError && message ? (
                    <Alert color="danger"> {message} </Alert>
                  ) : null}
                  <div className="p-2 mt-4">
                    <form onSubmit={handleSubmit(onSubmit)} action="#">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="password-input">
                          New Password
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                            name="new_password"
                            type={visible ? "text" : "password"}
                            className="form-control pe-5"
                            placeholder="Enter new password"
                            {...register("new_password", {
                              required: true,
                            })}
                          />
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            onClick={() => setVisible(!visible)}
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                        {errors.new_password && (
                          <span className="text-danger">
                            Password is required
                          </span>
                        )}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="password-input">
                          Confirm Password
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                            name="confirm_password"
                            type={visible ? "text" : "password"}
                            className="form-control pe-5"
                            placeholder="Enter confirm password"
                            {...register("confirm_password", {
                              required: true,
                            })}
                          />
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            onClick={() => setVisible(!visible)}
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                        {errors.confirm_password && (
                          <span className="text-danger">
                            Password is required
                          </span>
                        )}
                      </div>

                      <div className="mt-4">
                        <button className="btn btn-warning w-100" type="submit">
                          {isLoading ? <Spinner size={"sm"} /> : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* </ParticlesAuth> */}
    </React.Fragment>
  );
}

export default ResetPassword;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import moment from "moment";
import Table from "../../components/tables/Table";
import { getAllUsers } from "../../redux/users/user.slices";

const UsersList = () => {
  const dispatch = useDispatch();

  const { users, loading } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getAllUsers({}));
  }, []);

  return (
    <>
      <div className="page-header">
        <div></div>
        <div className="ms-auto pageheader-btn">
          {/* <Link
            to={"/stories/add"}
            className="btn btn-outline-success ms-md-2 btn-icon"
          >
            <span>
              <i className="fe fe-user"></i>
            </span>
            New Story
          </Link> */}
        </div>
      </div>
      <div className="page">
        <div className="page-main">
          <div className="card">
            {loading ? (
              <div className=" h-97 mx-auto d-flex justify-content-center align-items-center">
                <Spinner size={"lg"} />
              </div>
            ) : (
              <Table
                data={users?.map((user) => ({
                  ...user,
                  name: `${user.firstname} ${user.lastname}`,
                  createdAt: moment(user.createdAt).format(
                    "MMM DD, YYYY hh:mm"
                  ),
                }))}
                title={`All Users : ${users?.length}`}
                // navLink="/stories/"
                columns={[
                  { name: "_id", label: "ID", options: { display: false } },
                  { name: "name", label: "FULL NAME" },
                  { name: "dob", label: "DOB" },
                  { name: "location", label: "LOCATION" },
                  { name: "phone", label: "PHONE NUMBER" },
                  { name: "email", label: "EMAIL" },
                  { name: "createdAt", label: "JOINED ON" },
                  { name: "occupation", label: "OCCUPATION" },
                ]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersList;

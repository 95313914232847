import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AddTransactions from "../../components/transactions/AddTransaction";

import {
  fetchAllTours,
  fetchTours,
  fetchToursMonthlyViews,
} from "../../redux/tours/tours.slices";
import { BarChart } from "../../components/charts/BarChart";
import { getAllUsers } from "../../redux/users/user.slices";
import { Card, CardBody, CardHeader } from "reactstrap";
import { numberWithCommas } from "../../utils/numbersComma";

const Dashboard = () => {
  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.users);

  const { tours, views } = useSelector((state) => state.tours);

  useEffect(() => {
    dispatch(fetchTours({}));
    dispatch(fetchToursMonthlyViews({}));
    dispatch(getAllUsers({}));
  }, []);

  // //printe

  // useEffect(() => {
  //   if (!user) {
  //     navigate("/login");
  //   }
  // }, [user, navigate]);

  return (
    <>
      <div className="page-header">
        <div></div>
        <div className="ms-auto pageheader-btn">
          <Link
            to={"/tours/add"}
            className="btn btn-success btn-icon text-white me-2"
          >
            <span>
              <i className="fe fe-currency"></i>
            </span>
            Add New Tour
          </Link>

          <Link
            to={"/stories/add"}
            className="btn btn-info ms-md-2 btn-icon text-white"
          >
            <span>
              <i className="fe fe-user"></i>
            </span>
            New Story
          </Link>
        </div>
      </div>
      <div className="page">
        <div className="page-main">
          {/* start of row */}
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div className="card bg-primary img-card box-primary-shadow">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="text-white">
                      <h2 className="mb-0 number-font">{tours?.length}</h2>
                      <p className="text-white mb-0">Total Tours </p>
                    </div>
                    <div className="ms-auto">
                      <i className="ri-map-pin-line text-white fs-40 me-2 mt-2"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div className="card  bg-success img-card box-success-shadow">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="text-white">
                      <h2 className="mb-0 number-font">100</h2>
                      <p className="text-white mb-0">Tours In Bucketlists</p>
                    </div>
                    <div className="ms-auto">
                      <i className="ri-heart-2-line text-white fs-40 me-2 mt-2"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div className="card bg-secondary img-card box-secondary-shadow">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="text-white">
                      <h2 className="mb-0 number-font">{users?.length}</h2>
                      <p className="text-white mb-0">Total Users</p>
                    </div>
                    <div className="ms-auto">
                      <i className="ri-team-line text-white fs-40 me-2 mt-2"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <Card className="col-sm-12 col-md-6 col-lg-6 col-xl-8 p-4">
              <BarChart dataPprops={{ income: [] }} />
            </Card>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <Card>
                <CardHeader className="d-flex justify-content-between align-items-center">
                  <h3>Top Tours</h3>
                  <Link className="text-decoration-underline" to={"/tours"}>
                    See All
                  </Link>
                </CardHeader>
                <CardBody>
                  {tours?.slice(0, 5)?.map((tour) => (
                    <div>
                      <h5 className="font-bold">{tour.space}</h5>
                      <div className="d-flex justify-content-between">
                        <div>
                          <i className="ri-star-line mx-2"></i>
                          <span>{tour.rating}</span>
                        </div>
                        <div>
                          <i class="ri-eye-line mx-2"></i>
                          <span>{numberWithCommas(tour?.views)} visits</span>
                        </div>
                      </div>
                      <br />
                      <hr />
                    </div>
                  ))}
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <AddTransactions />
    </>
  );
};

export default Dashboard;

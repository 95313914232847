import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { FormGroup, Modal, ModalBody, Row, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import { updateAirlineByID } from "../../redux/airlines/airline.slice";

const EditAirlineModal = ({ show, onCloseClick, airline }) => {
  const dispatch = useDispatch();
  const { isLoading, isError, message } = useSelector(
    (state) => state.categories
  );
  const { countries } = useSelector((state) => state.countries);

  console.log("====================================");
  console.log(airline);
  console.log("====================================");

  const [image, setImage] = useState(airline?.icon);
  const [name, setName] = useState(airline?.name);
  const [url, setUrl] = useState(airline?.description);
  const [country, setCountry] = useState(airline?.country?._id);

  useEffect(() => {
    setName(airline?.name);
    setUrl(airline?.url);
    setCountry(airline?.country?._id);
    setImage(airline?.icon);
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError]);

  const handleChange = (file) => {
    // if (window !== undefined) {
    let formData = new FormData();
    formData.append("thumb_nail", file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post("https://api.360africa.tours/api/v1/upload", formData, config)
      .then(({ data }) => {
        setImage(data.data[0].location);
      })
      .catch((error) => console.log(error));
    // }
  };

  const handleSave = () => {
    dispatch(
      updateAirlineByID({
        id: airline?._id,
        data: {
          name: name || airline?.name,
          // country: country || airline?.country?._id,
          url: url || airline?.url,
          icon: image || airline?.icon,
        },
      })
    );
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size="md">
      <ModalBody className="py-3 px-5">
        <form onSubmit={handleSave}>
          <div className="mt-2">
            <h3 className="mb-4 text-muted mx-auto w-100 text-center">
              Edit Airline
            </h3>
            <Row className="row-cols-lg-1 mt-6">
              <FormGroup>
                <label htmlFor="name">Airline Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  // defaultValue={country?.name}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="url">Airline Url</label>
                <input
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  name="url"
                  className="form-control"
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="icon">Airline Logo</label> <br />
                <img
                  src={image}
                  className="my-4"
                  alt=""
                  height={100}
                  width={100}
                />
                <div class="flex items-center justify-center w-full">
                  <FileUploader
                    handleChange={handleChange}
                    name="file"
                    // types={fileTypes}
                  />
                </div>
              </FormGroup>
            </Row>
          </div>
          <hr className="my-4" />
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn w-sm btn-primary"
              id="delete-record"
            >
              {isLoading ? <Spinner size={"sm"} /> : "Save"}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

EditAirlineModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  country: PropTypes.any,
};

export default EditAirlineModal;

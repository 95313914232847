import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getHostByID,
  reset,
  updateHostByID,
} from "../../redux/host/host.slice";
import ApproveModal from "../../components/common/ApproveModal";
import RejectModal from "../../components/common/RejectModalModal";

const HostDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const { host, isSuccess, loading } = useSelector((state) => state.hosts);

  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);

  useEffect(() => {
    dispatch(getHostByID(id));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setShowApproveModal(false);
      setShowRejectModal(false);
      toast.success("Success");
      dispatch(reset());
      navigate(-1);
    }
  }, [dispatch, navigate, isSuccess]);

  const handleUpdate = ({ status }) => {
    dispatch(updateHostByID({ id, data: { status } }));
  };

  return (
    <>
      {loading ? (
        <div className="h-280 mx-auto d-flex justify-content-center align-items-center">
          <Spinner size={"lg"} />
        </div>
      ) : (
        <>
          <div className="page-header">
            <div className="d-flex align-items-center g-4 justify-content-center">
              <Link to={"/hosts"} className="text-danger">
                Go back
              </Link>
              <div className="mx-4"> || </div>
              <span>{host?._id}</span>
            </div>
            <div className="ms-auto pageheader-btn">
              <button
                onClick={() => setShowApproveModal(!showApproveModal)}
                className="btn btn-outline-success btn-icon me-2"
              >
                <span>
                  <i className="fe fe-currency"></i>
                </span>
                Approve
              </button>
              <button
                onClick={() => setShowRejectModal(!showRejectModal)}
                className="btn btn-outline-danger me-2"
              >
                <span>
                  <i className="fe fe-bin"></i>
                </span>
                Reject
              </button>
            </div>
          </div>
          <div className="page">
            <div className="page-main">
              <div className="d-flex gap-4">
                <div className="card p-4">
                  <h1>Business Info</h1>
                  <hr />
                  <div className="d-flex my-2 justify-content-between">
                    <h5 className=" text-muted">Business Name</h5>
                    <h5>{host.businessName}</h5>
                  </div>
                  <div className="d-flex my-2 justify-content-between">
                    <h5 className=" text-muted">Person Name</h5>
                    <h5>{host.name}</h5>
                  </div>
                  <div className="d-flex my-2 justify-content-between">
                    <h5 className=" text-muted">Occupation</h5>
                    <h5>{host.occupation}</h5>
                  </div>
                  <div className="d-flex my-2 justify-content-between">
                    <h5 className=" text-muted">Status</h5>
                    <h5>{host.status}</h5>
                  </div>
                </div>
                <div className="card p-4">
                  <h1>Contact & Other Info</h1>
                  <hr />
                  <div className="d-flex my-2 justify-content-between">
                    <h5 className=" text-muted">Phone</h5>
                    <h5>{host.phoneNumber}</h5>
                  </div>
                  <div className="d-flex my-2 justify-content-between">
                    <h5 className=" text-muted">Email</h5>
                    <h5>{host.email}</h5>
                  </div>
                  <div className="d-flex my-2 justify-content-between">
                    <h5 className=" text-muted">Address</h5>
                    <h5>{host.address}</h5>
                  </div>
                  <div className="d-flex my-2 justify-content-between">
                    <h5 className=" text-muted">Description</h5>
                    <h5>{host.description}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <ApproveModal
        show={showApproveModal}
        onCloseClick={() => setShowApproveModal(!showApproveModal)}
        onApproveClick={() => handleUpdate({ status: "approved" })}
      />
      <RejectModal
        show={showRejectModal}
        onCloseClick={() => setShowRejectModal(!showRejectModal)}
        onRejectClick={() => handleUpdate({ status: "rejected" })}
      />
    </>
  );
};

export default HostDetails;

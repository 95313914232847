import React, { useEffect, useState } from "react";
import Table from "../../components/tables/Table";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner } from "reactstrap";
import AddRegionModal from "../../components/regions/AddRegionModal";
import { toast } from "react-toastify";
import {
  deleteregion,
  getRegions,
  reset,
} from "../../redux/regions/region.slice";
import { getCountries } from "../../redux/countries/country.slice";
import DeleteModal from "../../components/common/DeleteModal";
import EditRegionModal from "../../components/regions/EditRegionModal";
import DetailsRegionModal from "../../components/regions/DetailsRegionModal";

const RegionList = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [isDelete, setisDelete] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [isDetails, setIsDetails] = useState(false);
  const [region, setRegion] = useState(null);

  const { regions, loading, isSuccess } = useSelector((state) => state.regions);
  const { countries } = useSelector((state) => state.countries);

  useEffect(() => {
    dispatch(getRegions({}));
    dispatch(getCountries({}));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(reset());
      toast.success("Success");
      setShowForm(false);
      setisDelete(false);
      dispatch(getRegions({}));
    }
  }, [dispatch, isSuccess]);

  const handleDeleteRegion = () => {
    dispatch(deleteregion(region[0]));

    // delete country here
  };

  return (
    <>
      <div className="page-header">
        <div></div>
        <div className="ms-auto pageheader-btn">
          <Button onClick={() => setShowForm(!showForm)}>Add Region</Button>
        </div>
      </div>
      <div className="page mt-6">
        <div className="page-main">
          <div className="card">
            {loading ? (
              <div className=" h-97 mx-auto d-flex justify-content-center align-items-center">
                <Spinner size={"lg"} />
              </div>
            ) : (
              <Table
                data={regions?.data?.map((reg) => ({
                  name: reg.name,
                  country: reg.country.name,
                  description: reg.description,
                  _id: reg._id,
                  countryId: reg.country._id,
                  icon: reg.icon,
                }))}
                title={`All Regions : ${regions?.count}`}
                // navLink="/regions/"
                columns={[
                  { name: "_id", label: "ID", options: { display: false } },
                  { name: "name", label: "NAME" },
                  { name: "country", label: "COUNTRY" },
                  { name: "description", label: "DESCRIPTON" },
                  { name: "icon", label: "ICON", options: { display: false } },
                  {
                    name: "countryId",
                    label: "CountryId",
                    options: { display: false },
                  },
                  {
                    name: "Actions",
                    options: {
                      filter: true,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                          <div className="d-flex g-4">
                            <i
                              onClick={() => {
                                setRegion((prev) => tableMeta.rowData);
                                setIsDetails(!isDetails);
                              }}
                              className="ri-eye-line btn display-6 cursor-pointer text-info"
                            ></i>
                            <i
                              onClick={() => {
                                setRegion((prev) => tableMeta.rowData);
                                setisEdit(!isEdit);
                              }}
                              className="ri-edit-box-line btn display-6 cursor-pointer text-primary"
                            ></i>
                            <i
                              onClick={() => {
                                setRegion(tableMeta.rowData);
                                setisDelete(!isDelete);
                              }}
                              className="ri-delete-bin-4-line btn display-6 cursor-pointer text-danger"
                            ></i>
                          </div>
                        );
                      },
                    },
                  },
                ]}
              />
            )}
          </div>
        </div>
      </div>
      <AddRegionModal
        show={showForm}
        countries={countries?.data}
        onCloseClick={() => setShowForm(!showForm)}
      />
      {region && (
        <EditRegionModal
          show={isEdit}
          region={
            region && {
              _id: region[0],
              name: region[1],
              description: region[3],
              icon: region[4],
              countryId: region[5],
            }
          }
          onCloseClick={() => {
            setRegion(null);
            setisEdit(!isEdit);
          }}
        />
      )}
      <DetailsRegionModal
        show={isDetails}
        region={
          region && {
            _id: region[0],
            name: region[1],
            description: region[3],
            icon: region[4],
            countryId: region[5],
            country: region[2],
          }
        }
        onCloseClick={() => {
          setRegion(null);
          setIsDetails(!isDetails);
        }}
      />
      <DeleteModal
        show={isDelete}
        title={`Are you sure ${region && region[1]}?`}
        onCloseClick={() => setisDelete(false)}
        onDeleteClick={handleDeleteRegion}
      />
    </>
  );
};

export default RegionList;

import { Routes, Route, Navigate } from "react-router-dom";
import TheLayout from "../layouts/TheLayout";
import Signin from "../pages/auth/Signin";
import Signup from "../pages/auth/Signup";
import Dashboard from "../pages/home/Dashboard";
import NotFound from "../pages/error/404";

import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import Settings from "../pages/settings/Settings";
import Users from "../pages/users/Users";
import ToursList from "../pages/tours/Tours";
import StoriesList from "../pages/stories/Stories";
import AddStory from "../pages/stories/AddStory";
import AddTour from "../pages/tours/AddTour";
import TourDetails from "../pages/tours/TourDetails";
import StoryDetails from "../pages/stories/StoryDetails";
import ForgetPasswordPage from "../pages/auth/ForgetPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import UserProfile from "../pages/auth/Userprofile";
import EditTour from "../pages/tours/EditTour";
import HostList from "../pages/hosts/Hosts";
import HostDetails from "../pages/hosts/HostDetails";
import CountryList from "../pages/countries_and_regions/Countries";
import RegionList from "../pages/countries_and_regions/Regions";
import CategoriesList from "../pages/categories/CategoriesList";
import AirlinesList from "../pages/partners/Airlines";
import OTAgencies from "../pages/partners/OTAS";

const MainRoutes = () => (
  <Routes>
    {/** Protected Routes */}
    {/** Wrap all Route under ProtectedRoutes element */}
    <Route path="/" element={<ProtectedRoutes />}>
      <Route path="/" element={<TheLayout />}>
        <Route path="/" element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="settings" element={<Settings />} />
        <Route path="users" element={<Users />} />
        <Route path="tours" element={<ToursList />} />
        <Route path="tours/add" element={<AddTour />} />
        <Route path="tours/:id" element={<TourDetails />} />
        <Route path="tours/edit/:id" element={<EditTour />} />
        <Route path="hosts" element={<HostList />} />
        <Route path="hosts/:id" element={<HostDetails />} />
        <Route path="countries" element={<CountryList />} />
        <Route path="categories" element={<CategoriesList />} />
        <Route path="regions" element={<RegionList />} />
        <Route path="airlines" element={<AirlinesList />} />
        <Route path="otas" element={<OTAgencies />} />
        <Route path="stories" element={<StoriesList />} />
        <Route path="stories/add" element={<AddStory />} />
        <Route path="stories/:id" element={<StoryDetails />} />
        <Route path="profile" element={<UserProfile />} />
      </Route>
    </Route>

    {/** Public Routes */}
    {/** Wrap all Route under PublicRoutes element */}
    <Route path="/" element={<PublicRoutes />}>
      <Route path="/login" element={<Signin />} />
      <Route path="/reset" element={<ForgetPasswordPage />} />
      <Route path="/reset/:token" element={<ResetPassword />} />
    </Route>

    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default MainRoutes;

import React from "react";
import { Link, NavLink } from "react-router-dom";
import _nav from "../routes/_naLinks";

const TheSideBar = () => {
  return (
    <div className="sticky">
      <div className="app-sidebar__overlay" data-bs-toggle="sidebar"></div>
      <aside className="app-sidebar bg-tertiary text-white">
        <div className="side-header bg-tertiary">
          <a className="header-brand1" href="index.html">
            <img
              src="./assets/images/brand/360africa.png"
              className="header-brand-img desktop-logo"
              alt="logo"
            />
            <img
              src="/360africa.png"
              className="header-brand-img toggle-logo"
              alt="logo"
            />
            <img
              src="/360africa.png"
              className="header-brand-img light-logo"
              height={20}
              alt="logo"
            />
            <img
              src="./assets/images/brand/360africa.png"
              className="header-brand-img light-logo1"
              alt="logo"
            />
          </a>
        </div>
        <div className="main-sidemenu">
          <div className="slide-left disabled" id="slide-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#7b8191"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
            </svg>
          </div>
          <ul className="side-menu">
            <li className="sub-category">
              <h3>Dashboard</h3>
            </li>
            {_nav.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {/* <li className="sub-category">
                    <h3>{item.label}</h3>
                  </li> */}
                  {item._children && (
                    <a
                      className="sub-category"
                      data-bs-toggle="collapse"
                      href=".collapseExample"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? "side-menu__item active-link"
                            : "side-menu__item"
                        }
                        data-bs-toggle="slide"
                        to={`${item.route}`}
                        end
                      >
                        <i className={item.icon}></i>
                        <span className="side-menu__label"> {item.name}</span>
                        {item._children && (
                          <i className="side-menu__sub-arrow fa fa-angle-right"></i>
                        )}
                      </NavLink>
                    </a>
                  )}
                  {!item._children && (
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "side-menu__item active-link"
                          : "side-menu__item"
                      }
                      data-bs-toggle="slide"
                      to={`${item.route}`}
                      end
                    >
                      <i className={item.icon}></i>
                      <span className="side-menu__label"> {item.name}</span>
                      {item._children && (
                        <i className="side-menu__sub-arrow fa fa-angle-right"></i>
                      )}
                    </NavLink>
                  )}
                  <li className="slide">
                    <div
                      className="collapse border-start collapse-horizontal ms-5 collapseExample"
                      // id="collapseExample"
                    >
                      {item._children &&
                        item._children.map((c, index) => (
                          <NavLink
                            key={index}
                            to={`${c.to}`}
                            className={(data) =>
                              data.isActive
                                ? "bg-danger active-link"
                                : "bg-transparent"
                            }
                            end
                          >
                            <p className="side-menu__item">{c.name}</p>
                          </NavLink>
                        ))}
                    </div>
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
          <div className="slide-right" id="slide-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#7b8191"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
            </svg>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default TheSideBar;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// const APP_URL = "http://localhost:5000/api/v1/stories/";
const APP_URL = process.env.REACT_APP_API_URL + "blogs/";

//get a story by id
export const fetchStoryById = createAsyncThunk(
  "stories/fetchStoryById",
  async (id, { getState }) => {
    try {
      const { data } = await axios.get(`${APP_URL}${id}`);
      return data;
    } catch (error) {
      return error.message;
    }
  }
);

//create a storie review
export const createstorieReview = createAsyncThunk(
  "stories/createstorieReview",
  async (review, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const { data } = await axios.post(
        `${APP_URL}${review.id}/reviews`,
        {
          comment: review.comment,
          rating: review.rating,
        },
        { headers }
      );
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return rejectWithValue(message);
    }
  }
);

//get all stories
export const fetchAllstories = createAsyncThunk(
  "stories/fetchAllstories",
  async () => {
    try {
      // const token = getState().auth.user.token;

      const { data } = await axios.get(APP_URL);
      // const stories = await response.json();
      return data;
    } catch (error) {
      return error.message;
    }
  }
);

//create slice

const storiesSlice = createSlice({
  name: "stories",
  initialState: {
    loading: false,
    storiesList: [],
    error: null,
    stories: [],
    message: "",
    story: {},
    bucket: {},
    buckets: [],
    success: false,
  },
  reducers: {
    reset: (state) => {
      state.stories = [];
      state.loading = false;
      state.error = null;
      state.message = "";
      state.story = {};
      state.bucket = {};
      state.buckets = [];
      state.success = false;
    },
  },
  extraReducers: {
    [fetchAllstories.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllstories.fulfilled]: (state, action) => {
      state.loading = false;
      state.storiesList = action.payload;
    },
    [fetchAllstories.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchStoryById.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchStoryById.fulfilled]: (state, action) => {
      state.loading = false;
      state.story = action.payload;
    },
    [fetchStoryById.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.message = action.payload;
    },
    [createstorieReview.pending]: (state, action) => {
      state.loading = true;
    },
    [createstorieReview.fulfilled]: (state, action) => {
      state.loading = false;
      state.storie = action.payload;
      state.success = true;
    },
    [createstorieReview.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.message = action.payload;
    },
  },
});
export const { reset } = storiesSlice.actions;
export default storiesSlice.reducer;

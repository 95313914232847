import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import airlineService from "./airline.service";

const initialState = {
  airline: {},
  airlines: [],
  loading: false,
  isSuccess: false,
  isError: false,
  message: "",
};

//add airline
export const addAirline = createAsyncThunk(
  "airline/addAirline",
  async (airline, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const response = await airlineService.addAirline(token, airline);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//get airlines list
export const getAirlines = createAsyncThunk(
  "airline/getAirlines",
  async (_, { rejectWithValue }) => {
    try {
      const response = await airlineService.getAirlines();
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//get airline by ID
export const getAirlineByID = createAsyncThunk(
  "airline/getAirlineByID",
  async (id, { rejectWithValue }) => {
    try {
      const response = await airlineService.getAirlineByID(id);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//update airline by ID
export const updateAirlineByID = createAsyncThunk(
  "airline/updateAirlineByID",
  async (airline, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const response = await airlineService.updateAirlineByID(
        token,
        airline.id,
        airline.data
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//delete airline list
export const deleteAirline = createAsyncThunk(
  "airline/deleteAirline",
  async (id, { rejectWithValue, getState }) => {
    try {
      const response = await airlineService.deleteAirline(
        id,
        getState().auth.user.token
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

const airlineSlice = createSlice({
  name: "airline",
  initialState,
  reducers: {
    reset: (state) => {
      state.airline = {};
      state.loading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: {
    [addAirline.pending]: (state, action) => {
      state.loading = true;
    },
    [addAirline.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
    },
    [addAirline.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [getAirlines.pending]: (state, action) => {
      state.loading = true;
    },
    [getAirlines.fulfilled]: (state, action) => {
      state.airlines = action.payload;
      state.loading = false;
    },
    [getAirlines.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [getAirlineByID.pending]: (state, action) => {
      state.loading = true;
    },
    [getAirlineByID.fulfilled]: (state, action) => {
      state.airline = action.payload;
      state.loading = false;
    },
    [getAirlineByID.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [updateAirlineByID.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAirlineByID.fulfilled]: (state, action) => {
      state.airline = action.payload;
      state.loading = false;
      state.isSuccess = true;
    },
    [updateAirlineByID.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [deleteAirline.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAirline.fulfilled]: (state, action) => {
      state.airline = action.payload;
      state.isSuccess = true;
      state.loading = false;
    },
    [deleteAirline.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
  },
});

export default airlineSlice.reducer;
export const { reset } = airlineSlice.actions;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import countryService from "./country.service";
import countriesService from "./country.service";

const initialState = {
  country: {},
  coountries: [],
  loading: false,
  isSuccess: false,
  isError: false,
  message: "",
};

//add country
export const addCountry = createAsyncThunk(
  "country/addCountry",
  async (country, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const response = await countryService.addCountry(token, country);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//get countries list
export const getCountries = createAsyncThunk(
  "country/getCountries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await countriesService.getCountries();
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//get country by ID
export const getCountryByID = createAsyncThunk(
  "country/getCountryByID",
  async (id, { rejectWithValue }) => {
    try {
      const response = await countriesService.getCountryByID(id);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//update country by ID
export const updateCountryByID = createAsyncThunk(
  "country/updateCountryByID",
  async (country, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const response = await countryService.updateCountryByID(
        token,
        country.id,
        country.data
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

//delete country list
export const deleteCountry = createAsyncThunk(
  "country/deleteCountry",
  async (id, { rejectWithValue, getState }) => {
    try {
      const response = await countryService.deleteCountry(
        id,
        getState().auth.user.token
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    reset: (state) => {
      state.country = {};
      state.loading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: {
    [addCountry.pending]: (state, action) => {
      state.loading = true;
    },
    [addCountry.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
    },
    [addCountry.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [getCountries.pending]: (state, action) => {
      state.loading = true;
    },
    [getCountries.fulfilled]: (state, action) => {
      state.countries = action.payload;
      state.loading = false;
    },
    [getCountries.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [getCountryByID.pending]: (state, action) => {
      state.loading = true;
    },
    [getCountryByID.fulfilled]: (state, action) => {
      state.country = action.payload;
      state.loading = false;
    },
    [getCountryByID.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [updateCountryByID.pending]: (state, action) => {
      state.loading = true;
    },
    [updateCountryByID.fulfilled]: (state, action) => {
      state.country = action.payload;
      state.loading = false;
      state.isSuccess = true;
    },
    [updateCountryByID.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
    [deleteCountry.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCountry.fulfilled]: (state, action) => {
      state.country = action.payload;
      state.isSuccess = true;
      state.loading = false;
    },
    [deleteCountry.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.message = action.error.message;
    },
  },
});

export default countrySlice.reducer;
export const { reset } = countrySlice.actions;

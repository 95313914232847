import axios from "axios";

// const API_URL = "http://localhost:5000/api/v1/";
const API_URL = process.env.REACT_APP_API_URL + "";

//get host list
const getHost = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "hosts", config);
  return response.data;
};

//get my host

const getMyHost = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "hosts/me", config);
  return response.data;
};

//get host by ID

const getHostByID = async (token, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "hosts/" + id, config);
  return response.data?.data;
};

//update host by ID

const updateHostByID = async (token, id, host) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.patch(API_URL + "hosts/" + id, host, config);
  return response.data?.data;
};

//delete host list
const deleteHost = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + "host/" + id, config);
  return response.data;
};

const hostService = {
  getHost,
  getHostByID,
  getMyHost,
  deleteHost,
  updateHostByID,
};

export default hostService;

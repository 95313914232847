import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import historyService from "./history.service";

const initialState = {
  histories: [],
  history: {},
  loading: false,
  success: false,
  isError: false,
  message: "",
};

//add history
export const addHistory = createAsyncThunk(
  "histories/addHistory",
  async (history, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await historyService.addHistory(history, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get historys
export const getHistories = createAsyncThunk(
  "histories/getHistories",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState()?.auth?.user?.token;

      return await historyService.getHistories(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const historySlice = createSlice({
  name: "histories",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addHistory.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addHistory.fulfilled, (state, action) => {
        console.log("payload add : ", action.payload);
        state.loading = false;
        state.success = true;
        state.histories.push(action.payload.data);
        state.message = "history added successfully";
      })
      .addCase(addHistory.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getHistories.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getHistories.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.histories = action.payload;
      })
      .addCase(getHistories.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

const { actions, reducer } = historySlice;

export const { reset } = actions;

export default reducer;

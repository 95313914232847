export const categoryies = [
  {
    id: "1",
    category: "Castles & Forts",
  },
  {
    id: "2",
    category: "Culture",
  },
  {
    id: "3",
    category: "Wildlife",
  },
  {
    id: "4",
    category: "Nature",
  },
  {
    id: "5",
    category: "Adventure",
  },
  {
    id: "6",
    category: "Waterfalls",
  },
  {
    id: "7",
    category: "Iconic landmarks",
  },
  {
    id: "8",
    category: "Beach",
  },
  {
    id: "9",
    category: "National Parks",
  },
  {
    id: "10",
    category: "Luxury",
  },
  {
    id: "11",
    category: "Resorts",
  },
  {
    id: "12",
    category: "BnB",
  },
  {
    id: "13",
    category: "Beachfront",
  },
  {
    id: "14",
    category: "Hotels",
  },
  {
    id: "15",
    category: "Apartments",
  },
  {
    id: "16",
    category: "Lodges",
  },
  {
    id: "17",
    category: "Videos",
  },
];
